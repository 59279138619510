export const getDeliveryGroupsByClientByIsActive = /* GraphQL */ `
  query GetDeliveryGroupsByClientByIsActive(
    $clientId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDeliveryGroupsByClientByIsActive(
      clientId: $clientId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        isActive
        name
        description
      }
      nextToken
    }
  }
`;

export const getElderDeliveryGroupsByClientByStatus = /* GraphQL */ `
  query GetElderDeliveryGroupsByClientByStatus(
    $clientId: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelElderDeliveryGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getElderDeliveryGroupsByClientByStatus(
      clientId: $clientId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        elderId
        deliveryGroupId
        clientId
        status
        sortOrder
      }
      nextToken
    }
  }
`;

export const getRestaurantMealsByRestaurantByIsActive = /* GraphQL */ `
  query GetRestaurantMealsByRestaurantByIsActive(
    $restaurantId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantMealsByRestaurantByIsActive(
      restaurantId: $restaurantId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        isActive
        name
        description
        price
        cost
        surcharges {
          name
          price
        }
      }
      nextToken
    }
  }
`;

export const getRestaurantHolidayByRestaurantByYear = /* GraphQL */ `
  query GetRestaurantHolidayByRestaurantByYear(
    $restaurantId: ID
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantHolidayByRestaurantByYear(
      restaurantId: $restaurantId
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        year
        closedDays
      }
      nextToken
    }
  }
`;
