import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DataTable from 'components/Table/DataTable';
import {
  listRestaurants,
  getRestaurantsByCountyByIsActive,
  getRestaurantsByClientByIsActive,
  getRestaurantsByRestaurantOwnerIdByIsActive,
} from 'graphql/queries';
import {
  formatAddress,
  formatAddressLatLng,
  formatTags,
} from 'utilities/format';
import RestaurantDetails from 'components/RestaurantDetails';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import RestaurantEditButton from 'forms/RestaurantForm/RestaurantEditButton';
import ImagePreviewAvatar from 'components/ImagePreviewAvatar';
import {
  counties,
  LAUNCH_DATE,
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { getClientIdSchema } from 'forms/schemas';
import { useCache } from 'CacheProvider';

const past = moment(LAUNCH_DATE).tz(TIME_ZONE).format('YYYY-MM-DD');
const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');

export default function RestaurantTable({
  title = i18n.t('餐廳'),
  description,
  restaurantOwnerId,
  clientId: inClientId,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [listQuery, setListQuery] = useState();
  const [clientOptions, setClientOptions] = useState([]);
  const { appGroup } = useCache();

  const clientId = inClientId || cache.get('app:facilityId');
  const adminMode = appGroup === 'Admins';
  const orgMode = appGroup === 'OrgAdmins';
  const facilityMode = appGroup === 'FacilityAdmins';

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns}>
          <RestaurantDetails id={id} isNested={true} />
        </NestedTableContainer>
      );
    },
  };
  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'isActive',
      label: '使用中',
      type: 'checkbox',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'restaurantOwnerId',
      label: '管理員 ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'owner',
      label: '管理員帳號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'imageS3Keys',
      label: '圖片',
      options: {
        filter: false,
        sort: false,
        customBodyRender(imageS3Keys) {
          return (
            <ImagePreviewAvatar
              imageS3Keys={imageS3Keys}
            />
          );
        },
      },
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        // filterList: [cache.get('app:location')].filter((x) => x),
        filter: true,
        sort: true,
      },
    },
    {
      name: 'clientId',
      label: '機構',
      options: {
        filter: true,
        sort: true,
        customBodyRender(clientId) {
          if (clientOptions) {
            return (clientOptions.find(({ id }) => id === clientId) || {}).name || '';
          }
          return '';
        },
      },
      hideInFacilityMode: true,
    },
    {
      name: 'isActive',
      label: '使用中',
      type: 'checkbox',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'isPublic',
      label: '公開',
      type: 'checkbox',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'freeDelivery',
      label: '免運費',
      type: 'checkbox',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'category',
      label: '類別',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '名稱',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'tags',
      label: '標籤',
      options: {
        filter: false,
        sort: false,
        customBodyRender: formatTags,
      },
    },
    {
      name: 'phoneNumber',
      label: '電話',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'address',
      label: '地址',
      options: {
        filter: false,
        sort: true,
        customBodyRender: formatAddress,
      },
    },
    {
      name: 'address',
      label: '地址經緯度',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatAddressLatLng,
      },
    },
    {
      name: 'serviceAreas',
      label: '服務範圍',
      options: {
        filter: false,
        sort: true,
        customBodyRender(serivceAreas) {
          if (serivceAreas && serivceAreas.length !== 0) {
            return serivceAreas.map(({ county, district }) => county + district).join('、 ');
          }
          return '';
        },
      },
    },
    {
      name: 'isAddedToPandago',
      label: 'Pandago',
      type: 'checkbox',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/restaurant/${id}`}
              label={`前往${t('餐廳')}頁面`}
            />
          );
        },
      },
    },
  ].filter(({ hideInFacilityMode, hideInOrgMode }) => !(facilityMode && hideInFacilityMode) && !(orgMode && hideInOrgMode));

  useEffect(() => {
    if (restaurantOwnerId) {
      setListQuery({
        name: getRestaurantsByRestaurantOwnerIdByIsActive,
        params: {
          restaurantOwnerId,
        },
      });

      return;
    }

    if (clientId) {
      setListQuery({
        name: getRestaurantsByClientByIsActive,
        params: {
          clientId,
        },
      });

      return;
    }

    setIndexes([
      {
        name: 'getRestaurantsByCountyByIsActive',
        label: '按地區/狀態查詢',
        partitionKey: 'county',
        sortKey: 'isActive',
        fields: [{
          label: '縣市',
          key: 'county',
          type: 'select',
          options: counties.map((item) => {
            return { value: item, label: item };
          }),
        }, {
          label: '使用狀態',
          key: 'isActive',
          type: 'select',
          options: [
            { value: undefined, label: '全部' },
            { value: 1, label: '使用中' },
            { value: 0, label: '已停用' },
          ],
        }],
        operation: getRestaurantsByCountyByIsActive,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '創立時間',
          key: 'createdAt',
          type: 'datetime',
        }],
        operation: listRestaurants,
      },
    ]);
    setQueryParams({
      county: cache.get('app:location') || counties[0],
      isActive: undefined,
      createdAt: {
        from: past,
        to: todayDate,
      },
    });
  }, [restaurantOwnerId]);

  useEffect(() => {
    if (adminMode) {
      (async () => {
        const [
          clientOptions,
        ] = await Promise.all([
          getClientIdSchema(null, '所屬機構', true),
        ]);
        console.log(clientOptions);
        setClientOptions(clientOptions);
      })();
    }
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        editButton={RestaurantEditButton}
        listQuery={listQuery}
        {...props}
      />
    </React.Fragment>
  );
}

RestaurantTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  restaurantOwnerId: PropTypes.string,
  clientId: PropTypes.string,
};
