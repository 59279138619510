import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Table from 'components/Table/Table';
import { asyncListAll } from 'utilities/graph';
// import FormDialog from 'components/FormDialog';
import { sortBy } from 'utilities/sorting';
import QueryGenerator from 'components/QueryGenerator';
// import { Colors } from '@silvergatedelivery/constants';

export default function DataTable({
  data: inData,
  indexes,
  defaultIndex,
  queryDefaultParams = {},
  title = '資料',
  description,
  columns,
  options,
  listQuery,
  listQueries,
  queryFunc,
  disableUpdate,
  padding = 16,
  dataSortFunc,
  showQueryGenerator = true,
  onDataSelectionChange,
  customToolbarSelect,
  customDownloadData,
  children,
  ...props
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();
  const [params, setParams] = useState();
  // const [open, setOpen] = useState(false);
  // const [editItem, setEditItem] = useState();

  const handleUpdate = (items) => {
    setData([...items]);
  };

  useEffect(() => {
    if (inData) {
      setData(inData);
      return;
    }

    if (!listQuery && !listQueries && !queryFunc) {
      setData([]);
      return;
    }

    (async () => {
      try {
        setIsLoading(true);

        let records = [];

        if (listQuery) {
          records = await asyncListAll(listQuery.name, listQuery.params);
        } else
        if (listQueries) {
          await Promise.all(listQueries.map(async (listQuery) => {
            const data = await asyncListAll(listQuery.name, listQuery.params);
            records = [...records, ...data];
          }));
        } else
        if (queryFunc) {
          records = await queryFunc();
        } else {
          records = [];
        }

        setData(records.sort(dataSortFunc ? dataSortFunc : sortBy('createdAt', true)));

        // testing
        if (process.env.NODE_ENV === 'development') {
          // setOpen(true);
          // setEditItem(records[0]);
        }
      } catch (e) {
        global.logger.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [inData, lastUpdatedAt, listQuery, listQueries, queryFunc, dataSortFunc]);

  const onRowSelectionChange = (_currentRowsSelected, _allRowsSelected, rowsSelected) => {
    if (onDataSelectionChange) {
      onDataSelectionChange(data.filter((_d, index) => rowsSelected.includes(index)));
    }
  };

  // global.logger.debug('indexes', { indexes, queryDefaultParams });

  return (
    <Grid container spacing={1} style={{ padding, display: 'block', width: '100%' }}>
      {indexes && indexes.length > 0 &&
      <Grid item xs={12} >
        <QueryGenerator
          indexes={indexes}
          defaultIndex={defaultIndex}
          defaultParams={queryDefaultParams}
          onUpdate={(data, params) => {
            handleUpdate(data);
            setParams(params);
          }}
          refreshAt={lastUpdatedAt}
          display={showQueryGenerator}
          appendNewData={true}
        />
      </Grid>}
      {children && <Grid item xs={12}>{children(data, params)}</Grid>}
      <Grid item xs={12}>
        <Table
          title={title}
          isLoading={isLoading}
          description={description}
          data={data}
          columns={columns}
          options={options}
          // onAddItem={() => setOpen(true)}
          // onBatchAdd={onBatchAdd}
          // onUpdateItem={disableUpdate ? undefined : (item)=>{
          //   setOpen(true);
          //   setEditItem(item);
          // }}
          onRowSelectionChange={onRowSelectionChange}
          customToolbarSelect={customToolbarSelect}
          customDownloadData={customDownloadData}
          onRefresh={(updatedData) => {
            global.logger.debug('onRefresh', updatedData);
            try {
              if (updatedData) {
                if (Array.isArray(updatedData)) {
                  updatedData.forEach((updatedDataItem) => {
                    const matched = data.find((x) => x.id === updatedDataItem.id);
                    if (matched) {
                      Object.assign(matched, updatedDataItem);
                    } else {
                      // TODO: handle not matched
                    }
                  });
                  setData([...data]);
                } else {
                  const matched = data.find((x) => x.id === updatedData.id);
                  if (matched) {
                    Object.assign(matched, updatedData);
                    setData([...data]);
                  } else
                  if (updatedData.id) {
                    setData([updatedData, ...data]);
                  } else {
                    setLastUpdatedAt(Date.now());
                  }
                }
              } else {
                setLastUpdatedAt(Date.now());
              }
            } catch (e) {
              setLastUpdatedAt(Date.now());
            }
          }}
          {...props}
        />
      </Grid>
      {/* {open &&
        <FormDialog
          title={title}
          openOnInit={true}
          onClose={() => {
            setOpen(false);
            setEditItem();
          }}
        >
          <props.form
            formData={editItem}
            onComplete={() => {
              setOpen(false);
              setLastUpdatedAt(Date.now());
              setEditItem();
            }}
          />
        </FormDialog>} */}
    </Grid>
  );
}

DataTable.propTypes = {
  data: PropTypes.array,
  indexes: PropTypes.array,
  defaultIndex: PropTypes.number,
  queryDefaultParams: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  columns: PropTypes.array,
  options: PropTypes.object,
  listQuery: PropTypes.object,
  listQueries: PropTypes.array,
  queryFunc: PropTypes.func,
  form: PropTypes.func,
  disableUpdate: PropTypes.bool,
  padding: PropTypes.number,
  dataSortFunc: PropTypes.func,
  showQueryGenerator: PropTypes.bool,
  onDataSelectionChange: PropTypes.func,
  customToolbarSelect: PropTypes.func,
  customDownloadData: PropTypes.func,
  children: PropTypes.func,
};
