import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import HomeIcon from '@material-ui/icons/Home';
import StorefrontIcon from '@material-ui/icons/Storefront';

// import Blogs from './Blogs/Blogs';
// import Blog from './Blogs/Blog';
import Download from './Download';
import DeleteMyAccount from './DeleteMyAccount';
import LandingPage from './LandingPage';
import Menu from './Menu/Menu';
import Terms from 'components/Terms';
import FacilitySignUp from 'views/FacilityAdmin/components/FacilitySignUp';

const source = [
  {
    title: '首頁',
    icon: HomeIcon,
    paths: [{ path: '/', exact: true }],
    component: LandingPage,
    hideFromMenu: true,
    showOnHeader: true,
  },
  {
    title: '機構訂購',
    icon: StorefrontIcon,
    paths: [{ path: '/menu', exact: true }],
    component: Menu,
    hideFromMenu: true,
    showOnHeader: true,
  },
  {
    title: '銀色大門隱私權及保護政策',
    paths: [{ path: '/privacy-policy', exact: true }],
    component: () => <Terms filename="銀色大門隱私權及保護政策.pdf" />,
    hideFromMenu: true,
    showOnHeader: false,
  },
  {
    title: '銀色大門會員服務條款',
    paths: [{ path: '/service-terms', exact: true }],
    component: () => <Terms filename="銀色大門會員服務條款.pdf" />,
    hideFromMenu: true,
    showOnHeader: false,
  },
  {
    title: '退貨退款政策須知',
    paths: [{ path: '/return-policy', exact: true }],
    component: () => <Terms filename="銀色大門退貨退款政策.pdf" />,
    hideFromMenu: true,
    showOnHeader: false,
  },
  // {
  //   title: '送餐關懷',
  //   icon: FavoriteBorderIcon,
  //   paths: [{ path: '/blogs', exact: true }],
  //   component: Blogs,
  //   hideFromMenu: true,
  //   showOnHeader: true,
  // },
  // {
  //   title: '',
  //   paths: [{ path: '/blogs/:id', exact: true }],
  //   component: Blog,
  //   hideFromMenu: true,
  //   showOnHeader: false,
  // },
  {
    title: 'APP下載',
    icon: GetAppIcon,
    paths: [{ path: '/download', exact: true }],
    component: Download,
    hideFromMenu: true,
    showOnHeader: true,
  },
  {
    title: '刪除帳號',
    icon: GetAppIcon,
    paths: [{ path: '/deleteMyAccount', exact: true }],
    component: DeleteMyAccount,
    hideFromMenu: true,
    showOnHeader: false,
  },
  {
    title: '申請機構帳戶',
    paths: [{ path: '/facilitySignUp', exact: true }],
    component: FacilitySignUp,
    hideFromMenu: true,
    showOnHeader: false,
  },
];

export default source;

export const routes = source.reduce((all, item) => {
  item.paths.forEach(({ path, link, exact = true }) => {
    all.push(Object.assign({ path, link, exact }, item));
  });
  return all;
}, []);
