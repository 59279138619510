const schema = {
  'title': '',
  'type': 'object',
  'required': [],
  'properties': {
    'surcharges': {
      'type': ['array', 'null'],
      'title': '額外成本',
      'description': '除了餐點以外的額外成本，例如：耗材紙盒，自費戶收據會分別列出額外成本',
      'items': {
        'required': [
          'name',
          'price',
        ],
        'type': 'object',
        'properties': {
          'name': {
            'type': 'string',
            'title': '名稱',
          },
          'price': {
            'type': 'number',
            'title': '售價',
            'default': 0,
          },
        },
      },
      'default': [],
    },
  },
};

export default schema;
