import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import { listClients, getClientsByTypeByCounty, getClientOrganizationByOrganization } from 'graphql/queries';
import { formatAddress } from 'utilities/format';
import ClientDetails from 'components/ClientDetails';
import ClientChip from 'components/ClientChip';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import IndividualClientEditButton from 'forms/IndividualClientForm/IndividualClientEditButton';
import { counties, translationTypes } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { asyncListAll } from 'utilities/graph';
import { useCache } from 'CacheProvider';

export default function IndividualClientTable({
  title = '個人',
  description,
  organizationId: inOrganizationId,
}) {
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [queryFunc, setQueryFunc] = useState();
  const { appGroup } = useCache();

  const organizationId = inOrganizationId || cache.get('app:organizationId');
  const orgMode = appGroup === 'OrgAdmins';

  const columns = [
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'isActive',
      label: '狀態',
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender(value) {
          return (
            <ClientChip status={value} />
          );
        },
      },
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '名字',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phoneNumber',
      label: '電話',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ wordBreak: 'break-all' }}>
            {value}
          </div>
        ),
      },
    },
    {
      name: 'username',
      label: '登入帳號',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ wordBreak: 'break-all' }}>
            {value}
          </div>
        ),
      },
      hideInOrgMode: true,
    },
    {
      name: 'address',
      label: '地址',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatAddress,
      },
    },
    {
      name: 'translation',
      label: '介面用詞',
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return value ? translationTypes.find((item) => item.value === value).label : '';
        },
      },
      hideInOrgMode: true,
    },
    {
      name: 'basicFee',
      label: '交通基本費',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },

    {
      name: 'basicDistanceInKM',
      label: '基本里程(公里)',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'extraFeeInKM',
      label: '每公里加成費',
      type: 'number',
      options: {
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/client/${id}`}
              label="前往客戶頁面"
            />
          );
        },
      },
      hideInOrgMode: true,
    },
  ].filter(({ hideInOrgMode }) => !(orgMode && hideInOrgMode));

  const options = {
    expandableRows: !orgMode,
    isRowExpandable: () => !orgMode,
    renderExpandableRow(rowData, rowMeta) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns}>
          <ClientDetails id={id} isNested={true} />
        </NestedTableContainer>
      );
    },
  };

  useEffect(() => {
    if (organizationId) {
      setQueryFunc(() => {
        return async () => {
          const clientOrganization = await asyncListAll(getClientOrganizationByOrganization, { organizationId });
          const clients = clientOrganization.reduce((accumulator, currentValue) => {
            if (currentValue.client && currentValue.client.type === 'individual') {
              accumulator.push(currentValue.client);
            }
            return accumulator;
          }, []);
          return clients;
        };
      });

      setIndexes(undefined);
      return;
    }

    setIndexes([
      {
        name: 'getClientsByTypeByCounty',
        label: '按地區查詢',
        partitionKey: 'type',
        sortKey: 'county',
        fields: [
          {
            label: '類別',
            key: 'type',
            type: 'select',
            options: [
              {
                label: '個人',
                value: 'individual',
              },
            ],
          },
          {
            label: '縣市',
            key: 'county',
            type: 'select',
            options: [
              { value: null, label: '全部' },
              ...counties.map((item) => {
                return { value: item, label: item };
              })],
          },
          {
            label: '姓名/名稱',
            key: 'name',
            comparisonOperator: 'contains',
          },
        ],
        operation: getClientsByTypeByCounty,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [{
          label: '類別',
          key: 'type',
          type: 'select',
          options: [
            {
              label: '個人',
              value: 'individual',
            },
          ],
        }, {
          label: '姓名',
          key: 'name',
          comparisonOperator: 'contains',
        }],
        operation: listClients,
      },
    ]);
    setQueryParams({
      type: 'individual',
      county: cache.get('app:location'),
    });
  }, [organizationId]);

  return (
    <DataTable
      title={title}
      description={description}
      columns={columns}
      options={options}
      indexes={indexes}
      queryDefaultParams={queryParams}
      queryFunc={queryFunc}
      editButton={orgMode ? undefined : IndividualClientEditButton}
    />
  );
}

IndividualClientTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  organizationId: PropTypes.string,
};
