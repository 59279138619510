import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import PrintIcon from '@material-ui/icons/Print';

import { Colors } from '@silvergatedelivery/constants';
import Loading from 'components/Loading';
import DateRangeSelector from 'components/DateRangeSelector';

import SummaryCard from './components/SummaryCard';
import PieCard from './components/PieCard';
import BarCard from './components/BarCard';
import LineCard from './components/LineCard';
import PlotCard from './components/PlotCard';
import {
  getOrders,
  getIssues,
  getLoginLogs,
  getStartEndByPeriodName,
} from './helpers';
import { getChartDataMappings } from './charts';
import cache from 'utilities/cache';

const periodOptions = [{
  label: '本日',
  value: 'day',
}, {
  label: '本週',
  value: 'week',
}, {
  label: '本月',
  value: 'month',
}, {
  label: '今年',
  value: 'year',
}];

const periodOptions2 = [{
  label: '昨日',
  value: 'day/-1/-1',
  // }, {
  //   label: '過去7日',
  //   value: 'day/-7/0',
}, {
  label: '上週',
  value: 'week/-1/-1',
}, {
  label: '前月',
  value: 'month/-1/-1',
  // }, {
  //   label: '過去3個月',
  //   value: 'month/-3/0',
}, {
  label: '去年',
  value: 'year/-1/-1',
}];

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: Colors.background.light,
    // minHeight: 'calc(100vh - 64px)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filterContainer: {
    padding: theme.spacing(2),
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonGroup: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  screenshotContainer: {
    display: 'none',
    visibility: 'hidden',
  },
}));

export default function Dashboard({ clientId: inClientId }) {
  const classes = useStyles();
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  });

  const downloadImage = (image, { name, extension = 'jpg' } = {}) => {
    const filename = name || `總攬-起訖日-輸出報表 ${startDate} - ${endDate}`;
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, filename);
    a.click();
  };

  const [data, setData] = useState({});
  const [chartData, setChartData] = useState();
  const [period, setPeriod] = useState(cache.get('app:dashboard:period') || 'day'); // day, week, month, year
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false);
  const [progressDate, setProgressDate] = useState('');

  const clientId = inClientId || cache.get('app:facilityId');

  const setCustomPeriod = ({ startDate, endDate }) => {
    setPeriod(`month/0/0/${startDate}/${endDate}`);
  };

  const getImage = async () => {
    const node = ref.current;

    try {
      setIsTakingScreenshot(true);

      await new Promise((resolve) => setTimeout(resolve, 300));

      const image = await takeScreenshot(node);

      return downloadImage(image);
    } catch (e) {
      console.error(e);
    } finally {
      setIsTakingScreenshot(false);
    }
  };

  useEffect(() => {
    if (!clientId) return;

    (async () => {
      setIsLoading(true);

      cache.set('app:dashboard:period', period);

      const {
        start,
        end,
        // prevStart,
        // prevEnd,
        startFormat,
        endFormat,
      } = getStartEndByPeriodName(period);

      setStartDate(startFormat);
      setEndDate(endFormat);

      global.logger.debug({ period, start, end });

      const onUpdateLastItem = (item, nextToken) => {
        if (nextToken) {
          setProgressDate(item.date);
        } else {
          setProgressDate('');
        }
      };

      const [
        orders,
        // prevOrders,
        issues,
        loginLogs,
        // prevIssues,
      ] = await Promise.all([
        getOrders(clientId, startFormat, endFormat, onUpdateLastItem),
        // getOrders(clientId, prevStart, prevEnd),
        getIssues(clientId, start, end),
        getLoginLogs(clientId, start, end),
        // getIssues(clientId, prevStart, prevEnd),
      ]);

      setData({
        orders,
        // prevOrders,
        issues,
        loginLogs,
        // prevIssues,
      });

      setIsLoading(false);
    })();
  }, [period, clientId]);

  useEffect(() => {
    if (!data.orders || !data.issues) return;

    const chartDataMappings = getChartDataMappings(data, period);
    const chartData = Object.keys(chartDataMappings)
      .map((key) => chartDataMappings[key]);

    global.logger.debug({ chartDataMappings, chartData });

    setChartData(chartData);
  }, [data]);

  console.log('isTakingScreenshot', isTakingScreenshot);

  return (
    <Container maxWidth={false} className={classes.container} ref={ref}>
      <div className={classes.screenshotContainer} >
        <img src={image} />
      </div>

      <Grid container spacing={2} style={{ display: isTakingScreenshot ? 'none' : 'block' }}>
        <Grid item xs={12}>
          <Paper className={classes.filterContainer}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Typography variant="h5" component="h1" style={{ flex: 1, paddingLeft: 8 }}>
                <DateRangeSelector
                  startDate={startDate}
                  endDate={endDate}
                  onUpdate={setCustomPeriod}
                />
              </Typography>

              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => setPeriod(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions2.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => setPeriod(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <Tooltip title="輸出頁面">
                <Button
                  onClick={getImage}
                  disabled={isLoading || isTakingScreenshot}
                >
                  <PrintIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: isTakingScreenshot ? 'block' : 'none' }}>
          <Typography variant="h3" component="h3" style={{ flex: 1, textAlign: 'center' }}>
            服務總覽表
          </Typography>
          <Typography variant="h5" component="h5" style={{ flex: 1, textAlign: 'center' }}>
            機構名稱：{cache.get('app:name')}
          </Typography>
          <Typography variant="h5" component="h5" style={{ flex: 1, textAlign: 'center' }}>
            服務區間：{startDate} - {endDate} &nbsp; 服務地區：{cache.get('app:location')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {isLoading &&
          <>
            <Loading fullScreen={false} />
            <Typography variant="body1" color="textPrimary" style={{ flex: 1, textAlign: 'center' }}>
              {progressDate && `讀取進度：${progressDate}，請稍候...`}
            </Typography>
          </>}

        {!isLoading && chartData &&
          <React.Fragment>
            {chartData.map((data, index) => (
              <Grid item xs={12} md={data.width || 2} key={index}>
                {renderChart(data, period, isTakingScreenshot ? 0 : 1)}
              </Grid>
            ))}
          </React.Fragment>}
      </Grid>
    </Container>
  );
}

Dashboard.propTypes = {
  clientId: PropTypes.string,
};

// elevation: 用來 workaround 輸出頁面圖片會有黑邊的問題
function renderChart({ type, title, value, prevValue, unit, appendix, data, data2, dataKeys, mode, colors }, period, elevation=1) {
  switch (type) {
    case 'summary':
      return (<SummaryCard title={title} value={value} prevValue={prevValue} unit={unit} appendix={appendix} period={period} elevation={elevation} />);
    case 'pie':
      return (<PieCard title={title} data={data} data2={data2} elevation={elevation} />);
    case 'bar':
      return (<BarCard title={title} data={data} dataKeys={dataKeys} mode={mode} colors={colors} elevation={elevation} />);
    case 'line':
      return (<LineCard title={title} data={data} colors={colors} elevation={elevation} />);
    case 'plot':
      return (<PlotCard title={title} data={data} elevation={elevation} />);
    default:
  }
}
