import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ResponsiveLine } from '@nivo/line';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  value: {
    margin: theme.spacing(1),
  },
}));

export default function LineCard({ title, data = [], colors, elevation=1 }) {
  const classes = useStyles();

  return (
    <Paper className={classes.container} elevation={elevation}>
      <Typography variant="subtitle2" component="p">
        {title}
      </Typography>
      <Grid container align="center" justifyContent="center">
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveLine
            data={data}
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            colors={colors || { scheme: 'nivo' }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: true,
              reverse: false,
            }}
            axisLeft={{
              tickValues: 5,
            }}
            axisTop={null}
            axisRight={null}
            enablePoints
            enablePointLabel
            enableGridX={false}
            tooltip={({ point }) => (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                }}
              >
                <strong>{point.serieId}</strong><br />
                {point.data.xFormatted} : {point.data.yFormatted}
              </div>
            )}
            useMesh={true}
            legends={[
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.85,
                symbolSize: 20,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Grid>
    </Paper>);
}


LineCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  colors: PropTypes.any,
  elevation: PropTypes.number,
};
