import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import EditButton from '../components/EditButton';
import DeliverStaffForm from './DeliverStaffForm';

export default function DeliverStaffEditButton({ ...props }) {
  if (props.mode === 'add') {
    return null;
  }

  return (
    <EditButton
      title={`${i18n.t('送餐大使')}`}
      form={DeliverStaffForm}
      {...props}
    />);
}

DeliverStaffEditButton.propTypes = {
  mode: PropTypes.string,
};
