import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DataForm from 'forms/DataForm';
import uiSchema from './uiSchema';

export default function SurchargesForm(props) {
  const { default: schema } = useMemo(() => require('./schema.js'), []);

  return (
    <DataForm
      schema={schema}
      uiSchema={uiSchema}
      dirty={true}
      hideSubmitButton
      {...props}
    />
  );
}

SurchargesForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
};
