import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import shortuuid from 'short-uuid';
import {
  counties,
  TIME_ZONE,
  orderSources,
  orderStatus,
  cancellationReasons,
} from '@silvergatedelivery/constants';
import Toolbar from '@material-ui/core/Toolbar';
import DataTable from 'components/Table/DataTable';
import OrderDialog from 'components/Order/OrderDialog';
import {
  listOrders,
  getOrdersByCountyByDate,
  getOrdersByClientByDate,
  getOrdersByElderByDate,
  getOrdersByRestaurantByCreatedAt,
  getOrdersByCountyByStatus,
  getOrdersByCountyBySource,
  getOrdersByDeliveryStaffByDate,
  getOrdersByStatusByDate,
  getOrdersByRestaurantByDate,
  getRecurringOrderLogByRecurringOrderIdByDate,
  getOrder,
} from 'graphql/queries';
import OrderStatusChip from 'components/OrderStatusChip';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import Order from 'components/Order/Order';
import LinkButton from 'components/Table/LinkButton';
import AdminOrderEditButton from 'forms/AdminOrderForm/AdminOrderEditButton';
import cache from 'utilities/cache';
import UpdateOrderToCompletedButton from 'components/Order/UpdateOrderToCompletedButton';
import UpdateOrderToCancelledButton from 'components/Order/UpdateOrderToCancelledButton';
import UpdateOrderDeliveryStaffButton from 'components/Order/UpdateOrderDeliveryStaffButton';
import UpdateOrderRestaurantButton from 'components/Order/UpdateOrderRestaurantButton';
import UpdateOrderTimeButton from 'components/Order/UpdateOrderTimeButton';
import UpdateOrderLinkGroupEldersButton from 'components/Order/UpdateOrderLinkGroupEldersButton';
import ResetCancelledOrderButton from 'components/Order/ResetCancelledOrderButton';
import PlaceLalamoveMultiStopButton from 'components/Order/PlaceLalamoveMultiStopButton';
import { getTranslatedOrderStatus, getTranslatedMealSlots } from 'utilities/translate';
import { formatAddress, formatAddressLatLng } from 'utilities/format';
import { asyncListAll, request } from 'utilities/graph';
import Loading from 'components/Loading';
import deliveryStaffFeeCalculationMethod from '@silvergatedelivery/constants/src/deliveryStaffFeeCalculationMethod';
import { getDeliveryStaffIdSchema } from 'forms/schemas';
import { useCache } from 'CacheProvider';

const lastWeek = moment().tz(TIME_ZONE).add(-7, 'days').format('YYYY-MM-DD');
const todayDate = moment().tz(TIME_ZONE).format('YYYY-MM-DD');
const nextWeek = moment().tz(TIME_ZONE).add(7, 'days').format('YYYY-MM-DD');

export default function OrderTable({
  title = '訂單',
  description,
  clientId,
  elderId,
  restaurantId,
  deliveryStaffId,
  recurringOrderId,
  queryStatus,
  defaultIndex = 0,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const ordersSelected = useRef([]);
  const [deliveryStaffOptions, setDeliveryStaffOptions] = useState();
  const { appGroup } = useCache();

  const translatedOrderStatus = getTranslatedOrderStatus();
  const notAdminMode = appGroup !== 'Admins';
  const defaultIndexes = [
    {
      name: 'getOrdersByCountyByDate',
      label: `按地區/${t('送餐')}時間/狀態查詢`,
      partitionKey: 'county',
      sortKey: 'date',
      fields: [{
        label: '縣市',
        key: 'county',
        type: 'select',
        options: counties.map((item) => {
          return { value: item, label: item };
        }),
      }, {
        label: `${t('送餐')}時間`,
        key: 'date',
        type: 'date',
      }, {
        label: '狀態',
        key: 'status',
        type: 'select',
        options: [{ value: undefined, label: '全部' }, ...translatedOrderStatus],
      }],
      operation: getOrdersByCountyByDate,
    },
    {
      name: 'getOrdersByCountyByDate',
      label: `按地區/${t('送餐')}時間/訂單來源查詢`,
      partitionKey: 'county',
      sortKey: 'date',
      fields: [{
        label: '縣市',
        key: 'county',
        type: 'select',
        options: counties.map((item) => {
          return { value: item, label: item };
        }),
      }, {
        label: `${t('送餐')}時間`,
        key: 'date',
        type: 'date',
      }, {
        label: '訂單來源',
        key: 'source',
        type: 'select',
        options: [{ value: undefined, label: '全部' }, ...orderSources],
      }],
      operation: getOrdersByCountyByDate,
    },
    {
      name: 'getOrdersByCountyByStatus',
      label: '按地區/狀態查詢',
      partitionKey: 'county',
      sortKey: 'status',
      fields: [{
        label: '縣市',
        key: 'county',
        type: 'select',
        options: counties.map((item) => {
          return { value: item, label: item };
        }),
      }, {
        label: '狀態',
        key: 'status',
        type: 'select',
        options: [{ value: undefined, label: '全部' }, ...translatedOrderStatus],
      }],
      operation: getOrdersByCountyByStatus,
    },
    {
      name: 'getOrdersByCountyBySource',
      label: '按地區/訂單來源查詢',
      partitionKey: 'county',
      sortKey: 'source',
      fields: [{
        label: '縣市',
        key: 'county',
        type: 'select',
        options: counties.map((item) => {
          return { value: item, label: item };
        }),
      }, {
        label: '訂單來源',
        key: 'source',
        type: 'select',
        options: [{ value: undefined, label: '全部' }, ...orderSources],
      }],
      operation: getOrdersByCountyBySource,
    },
    {
      name: 'getOrdersByStatusByDate',
      label: '按狀態查詢',
      partitionKey: 'status',
      sortKey: 'date',
      fields: [{
        label: '狀態',
        key: 'status',
        type: 'select',
        options: [{ value: undefined, label: '全部' }, ...translatedOrderStatus],
      }],
      operation: getOrdersByStatusByDate,
    },
    {
      name: 'scan',
      label: '掃描全部資料',
      fields: [{
        label: `${t('送餐')}時間`,
        key: 'date',
        type: 'date',
      }, {
        label: '狀態',
        key: 'status',
        type: 'select',
        options: [{ value: undefined, label: '全部' }, ...translatedOrderStatus],
      }],
      operation: listOrders,
    },
  ];

  const customToolbarSelect = ({ onUpdate }) =>
    <Toolbar>
      <UpdateOrderToCompletedButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderToCancelledButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <ResetCancelledOrderButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderDeliveryStaffButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderRestaurantButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderTimeButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <PlaceLalamoveMultiStopButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
      <UpdateOrderLinkGroupEldersButton
        ordersSelected={ordersSelected}
        onUpdate={onUpdate}
      />
    </Toolbar>;

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns} noPadding={true}>
          <Order id={id} />
        </NestedTableContainer>
      );
    },
    selectableRows: 'multiple',
    isRowSelectable: () => true,
  };
  const translatedMealSlots = getTranslatedMealSlots();
  const columns = [
    {
      name: 'id',
      label: '編號',
      isTemplate: true,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: t('取餐編號'),
      options: {
        filter: false,
        sort: true,
        customBodyRender(value) {
          return shortuuid().fromUUID(value).slice(-6);
        },
      },
    },
    {
      name: 'referenceId',
      label: '整合編號',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender(value) {
          return (value || '').split('-')[1] || '';
        },
      },
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        // filterList: usePreFilter ? [cache.get('app:location')].filter((x) => x) : [],
        filter: true,
        sort: true,
      },
    },
    {
      name: 'clientId',
      label: '機構/客戶編號',
      options: {
        filter: false,
        sort: true,
        display: notAdminMode ? 'excluded' : false,
      },
    },
    {
      name: 'client.name',
      label: '機構名稱/客戶姓名',
      options: {
        filter: true,
        sort: true,
        display: notAdminMode ? 'excluded' : true,
      },
    },
    {
      name: 'tier',
      label: '目前層級',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'category',
      label: '類別',
      options: {
        filter: true,
        sort: true,
        display: notAdminMode ? 'excluded' : false,
      },
    },
    {
      name: 'elderId',
      label: `${t('送餐對象')}編號`,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'elder.name',
      label: t('送餐對象'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'elder.address',
      label: '送餐地址',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender(value) {
          return formatAddress(value);
        },
        sortCompare(order) {
          return (obj1, obj2) => {
            const addr1 = formatAddress(obj1.data);
            const addr2 = formatAddress(obj2.data);
            if (addr1 === addr2) {
              return (obj1.rowData[0] + obj1.rowData[1] > obj2.rowData[0] + obj2.rowData[1] ? 1 : -1) * (order === 'asc' ? 1 : -1);
            }
            return (addr1 > addr2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      name: 'elder.address',
      label: '送餐地址經緯度',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatAddressLatLng,
      },
    },
    {
      name: 'restaurant.name',
      label: t('餐廳'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'deliveryStaffId',
      label: t('送餐大使'),
      options: {
        filter: false,
        sort: true,
        customBodyRender(deliveryStaffId) {
          if (deliveryStaffOptions) {
            const index = deliveryStaffOptions.enum.findIndex((id) => id === deliveryStaffId);
            if (index !== -1) {
              return deliveryStaffOptions.enumNames[index];
            }
          }
          return '';
        },
      },
    },
    {
      name: 'date',
      label: '日期',
      edit: {
        type: 'text',
      },
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'deliveryBy',
      label: '時間',
      type: 'time',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'mealSlot',
      label: '時段',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender(value) {
          return translatedMealSlots.find((item) => item.value === value).label;
        },
      },
    },
    {
      name: 'status',
      label: '狀態',
      edit: {
        type: 'text',
      },
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <OrderStatusChip status={value} />
          );
        },
        filterOptions: {
          renderValue: (v) => (orderStatus.find(({ value }) => value === v) || {}).label,
        },
        customFilterListOptions: {
          render: (v) => (orderStatus.find(({ value }) => value === v) || {}).label,
        },
      },
    },
    {
      name: 'cancellationReason',
      label: '訂單取消原因',
      options: {
        display: false,
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const reason = cancellationReasons.find((reason) => reason.value === value);
          if (reason) {
            return reason.label;
          }
          return '';
        },
      },
    },
    {
      name: 'cancellationNote',
      label: '訂單取消備註',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'direction.distance',
      label: '距離',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'noteForDelivery',
      label: `${t('送餐')}備註`,
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'noteForMeal',
      label: `${t('餐點')}備註`,
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'note',
      label: '備註',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'deliveryStaffFee',
      label: t('送餐費用'),
      type: 'currency',
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: 'deliveryStaffFeeCalculationMethod',
      label: '送餐費用計算方法',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (method) => {
          return (deliveryStaffFeeCalculationMethod.find(({ value }) => value === method) || {}).label;
        },
      },
    },
    {
      name: 'mealItems',
      label: t('餐點'),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (mealItems) => {
          return mealItems.map(({ name, quantity }) => `${name} x${quantity}`).join(', ');
        },
      },
    },
    {
      name: 'totalCost',
      label: '餐點總進價',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
        display: notAdminMode ? 'excluded' : true,
      },
    },
    {
      name: 'total',
      label: '餐點總金額',
      type: 'currency',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'source',
      label: '訂單來源',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: (source) => (orderSources.find((orderSource) => orderSource.value === source) || {}).label || '',
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(id) {
          return (
            <OrderDialog id={id} />
          );
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/order/${id}`}
              label="前往訂單頁面"
            />
          );
        },
      },
    },
  ];

  // const queryFunc = async () => {
  //   if (clientId) {
  //     return asyncListAll(getOrdersByClientByCreatedAt, { clientId });
  //   }

  //   if (elderId) {
  //     return asyncListAll(getOrdersByElderByCreatedAt, { elderId });
  //   }

  //   if (restaurantId) {
  //     return asyncListAll(getOrdersByRestaurantByCreatedAt, { restaurantId });
  //   }

  //   return asyncListAll(listOrders);
  // };

  useEffect(() => {
    if (deliveryStaffId) {
      const filterClientId = clientId || cache.get('app:facilityId');
      const indexes = [
        {
          name: 'getOrdersByDeliveryStaffByDate',
          label: `按${t('送餐大使')}查詢`,
          partitionKey: 'deliveryStaffId',
          sortKey: 'date',
          fields: [{
            label: `${t('送餐大使')}ID`,
            key: 'deliveryStaffId',
            type: 'select',
            options: [{ value: deliveryStaffId, label: deliveryStaffId }],
            disabled: true,
          }, {
            label: `${t('送餐')}時間`,
            key: 'date',
            type: 'date',
          }],
          operation: getOrdersByDeliveryStaffByDate,
        },
      ];
      if (filterClientId) {
        indexes[0].fields.push({
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: filterClientId, label: filterClientId }],
          disabled: true,
        });
      }
      setIndexes(indexes);
      setQueryParams({
        date: {
          from: lastWeek,
          to: nextWeek,
        },
        deliveryStaffId,
        clientId: filterClientId,
      });
    } else
    if (restaurantId) {
      const filterClientId = clientId || cache.get('app:facilityId');
      const indexes = [
        {
          name: 'getOrdersByRestaurantByDate',
          label: `按${t('餐廳')}/送餐時間查詢`,
          partitionKey: 'restaurantId',
          sortKey: 'date',
          fields: [{
            label: `${t('餐廳')} ID`,
            key: 'restaurantId',
            type: 'select',
            options: [{ value: restaurantId, label: restaurantId }],
            disabled: true,
          }, {
            label: `${t('送餐')}時間`,
            key: 'date',
            type: 'date',
          }],
          operation: getOrdersByRestaurantByDate,
        },
        {
          name: 'getOrdersByRestaurantByCreatedAt',
          label: `按${t('餐廳')}/下單時間查詢`,
          partitionKey: 'restaurantId',
          sortKey: 'createdAt',
          fields: [{
            label: `${t('餐廳')} ID`,
            key: 'restaurantId',
            type: 'select',
            options: [{ value: restaurantId, label: restaurantId }],
            disabled: true,
          }, {
            label: '下單時間',
            key: 'createdAt',
            type: 'datetime',
          }],
          operation: getOrdersByRestaurantByCreatedAt,
        },
      ];
      if (filterClientId) {
        indexes[0].fields.push({
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: filterClientId, label: filterClientId }],
          disabled: true,
        });
        indexes[1].fields.push({
          label: '機構',
          key: 'clientId',
          type: 'select',
          options: [{ value: filterClientId, label: filterClientId }],
          disabled: true,
        });
      }
      setIndexes(indexes);
      setQueryParams({
        date: {
          from: todayDate,
          to: todayDate,
        },
        createdAt: {
          from: todayDate,
          to: todayDate,
        },
        restaurantId,
        clientId: filterClientId,
      });
    } else
    if (clientId) {
      setIndexes([
        {
          name: 'getOrdersByClientByDate',
          label: '按客戶查詢',
          partitionKey: 'clientId',
          sortKey: 'date',
          fields: [{
            label: '客戶 ID',
            key: 'clientId',
            type: 'select',
            options: [{ value: clientId, label: clientId }],
            disabled: true,
          }, {
            label: `${t('送餐')}時間`,
            key: 'date',
            type: 'date',
          }],
          operation: getOrdersByClientByDate,
        },
      ]);
      setQueryParams({
        date: {
          from: lastWeek,
          to: nextWeek,
        },
        clientId,
      });
    } else
    if (elderId) {
      setIndexes([
        {
          name: 'getOrdersByElderByDate',
          label: '按長輩查詢',
          partitionKey: 'elderId',
          sortKey: 'date',
          fields: [{
            label: '長輩 ID',
            key: 'elderId',
            type: 'select',
            options: [{ value: elderId, label: elderId }],
            disabled: true,
          }, {
            label: `${t('送餐')}時間`,
            key: 'date',
            type: 'date',
          }],
          operation: getOrdersByElderByDate,
        },
      ]);
      setQueryParams({
        date: {
          from: lastWeek,
          to: nextWeek,
        },
        elderId,
      });
    } else if (recurringOrderId) {
      setIndexes([]);
      setQueryParams({});
      setData([]);
      (async () => {
        setIsLoading(true);
        try {
          const recurringOrderLogs = await asyncListAll(getRecurringOrderLogByRecurringOrderIdByDate,
            { recurringOrderId, date: { ge: moment().tz(TIME_ZONE).format('YYYY-MM-DD') } });
          const orderIds = recurringOrderLogs.map(({ orderIds }) => orderIds).flat();
          const orders = await Promise.all(orderIds.map(async (id) => {
            const { data: { getOrder: order } } = await request(getOrder, { id });
            return order;
          }));
          setData(orders);
        } catch (e) {
          console.error(e);
        }
        setIsLoading(false);
      })();
    } else {
      setIndexes(defaultIndexes);
      setQueryParams({
        date: {
          from: todayDate,
          to: todayDate,
        },
        status: queryStatus,
        county: cache.get('app:location') || counties[0],
      });
    }
  }, [elderId, clientId, restaurantId, recurringOrderId, queryStatus]);

  useEffect(() => {
    (async () => {
      const deliveryStaffOptions = await getDeliveryStaffIdSchema(null, null, null, true, true);
      setDeliveryStaffOptions(deliveryStaffOptions);
    })();
  }, []);

  const onDataSelectionChange = (dataSelected) => {
    ordersSelected.current = dataSelected;
  };

  if (!indexes || !queryParams) return null;

  if (isLoading) {
    return (<Loading fullScreen={false} />);
  }

  return (
    <React.Fragment>
      <DataTable
        data={data}
        defaultIndex={defaultIndex}
        indexes={indexes}
        queryDefaultParams={queryParams}
        title={title}
        description={description}
        columns={columns}
        options={options}
        // queryFunc={queryFunc}
        editButton={AdminOrderEditButton}
        selectableRows='multiple'
        onDataSelectionChange={onDataSelectionChange}
        customToolbarSelect={customToolbarSelect}
        {...props}
      />
    </React.Fragment>);
}

OrderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  clientId: PropTypes.string,
  elderId: PropTypes.string,
  deliveryStaffId: PropTypes.string,
  restaurantId: PropTypes.string,
  recurringOrderId: PropTypes.string,
  defaultIndex: PropTypes.number,
  queryStatus: PropTypes.string,
};
