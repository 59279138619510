import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Storage } from 'aws-amplify';
import TagChip from 'components/TagChip';
import { formatDatetime } from 'utilities/format';
import OpeningHoursDialog from '../components/OpeningHoursDialog';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    height: '100%',
  },
  mediaContainer: {
    position: 'relative',
    marginLeft: 10,
  },
  media: {
    height: 150,
    opacity: 0.1,
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
});

export default function MealItem({ order, onChecked, disableCheck=false, checked, onOrderUpdate }) {
  const [imgSrc, setImgSrc] = useState();
  const classes = useStyles();

  // TODO: 一訂單多餐點
  useEffect(() => {
    if (order?.mealItems[0]?.imageS3Keys[0]) {
      (async () => {
        const newImgSrc = await Storage.get(order.mealItems[0].imageS3Keys[0]);
        setImgSrc(newImgSrc);
      })();
    } else {
      setImgSrc('/images/meal.webp');
    }
  }, [order]);

  const { quotation } = order || {};
  const { date, time } = order?.dateTime || {};
  const { name, price, tags, restaurant, quantity } = order?.mealItems[0] || {};

  return (
    <Card className={classes.card}>
      <Grid container style={{ marginTop: 10 }}>
        <Grid item container xs={12} alignItems='center'>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              onChecked && onChecked(e.target.checked);
            }}
            disabled={disableCheck}
          />
          <Grid item>
            <Typography gutterBottom variant="h6" color="textPrimary">
              {restaurant.name}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <OpeningHoursDialog restaurant={restaurant} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.mediaContainer} >
            <CardMedia
              className={classes.media}
              image={imgSrc}
              title="照片"
              component="img"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/images/meal.webp';
              }}
            />
            <div className={classes.imageContainer}>
              <img className={classes.img}
                src={imgSrc}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/images/meal.webp';
                }}
              />
            </div>
          </div>
          <CardContent style={{ position: 'relative', padding: 8 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={8}>
                <Typography gutterBottom variant="body1" color="textPrimary">
                  {name} x {quantity}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography gutterBottom variant="body2" color="textSecondary" >
                  ${price}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography gutterBottom variant="body1" color="textPrimary">
                  運費
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {!quotation && <Typography gutterBottom variant="body2" color="error" >
                  計算中
                </Typography>}
                {quotation?.code === 'SUCCESS' && <Typography gutterBottom variant="body2" color="textSecondary" >
                  ${quotation?.deliveryStaffFee}
                </Typography>}
                {quotation?.code === 'OUT_OR_RANGE' && <Typography gutterBottom variant="body2" color="error" >
                  距離過遠，請重新選擇餐廳
                </Typography>}
              </Grid>
            </Grid>

            {tags.items.map(({ tag }, index)=>(
              <TagChip
                key={index}
                tag={tag}
              />
            ))}
          </CardContent>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent='center' spacing={1}>
            <Grid item xs={10}>
              <TextField
                variant='outlined'
                label='日期'
                value={formatDatetime(date, { year: false, weekday: true, hour: false, minute: false })}
                onChange={(e) => {}}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant='outlined'
                label='時間'
                value={time}
                onChange={(e) => {}}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant='outlined'
                label='餐點備註'
                value={order.noteForMeal || ''}
                onChange={(e) => {
                  onOrderUpdate && onOrderUpdate({ noteForMeal: e.target.value });
                }}
              />
            </Grid>
            {order.restaurantClosed &&
            <Grid item xs={10}>
              <Typography variant="body1" color="error">
                餐廳無營業
              </Typography>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

MealItem.propTypes = {
  order: PropTypes.object,
  onChecked: PropTypes.func,
  disableCheck: PropTypes.bool,
  checked: PropTypes.bool,
  onOrderUpdate: PropTypes.func,
};
