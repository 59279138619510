import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import moment from 'moment-timezone';
import deepcopy from 'deepcopy';
import { CSVLink } from 'react-csv';
import ReactJson from 'react-json-view';
import { toastr } from 'react-redux-toastr';

import { makeStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import SyncIcon from '@material-ui/icons/Sync';
import AddIcon from '@material-ui/icons/Add';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
// import QueueIcon from '@material-ui/icons/Queue';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';

import Footer from './Footer';
import { Colors, TIME_ZONE } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { formatDatetimeFromNow } from 'utilities/format';
import { useCache } from 'CacheProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    flex: 1,
    height: '100%',
    width: '100%',
  },
  numberContainer: {
    textAlign: 'right',
    paddingRight: theme.spacing(1),
  },
  loadingContainer: {
    position: 'absolute',
    zIndex: 110,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255,255,255,0.8)',
  },
}));

const theme = (props = {}) => {
  const { cell = {} } = props;
  const cellStyle = Object.assign({
    minWidth: 48,
    maxWidth: 200,
  }, cell);

  return createTheme({
    palette: {
      primary: {
        light: Colors.primaryLight,
        main: Colors.primary,
        dark: Colors.primaryDark,
        contrastText: '#fff',
      },
    },
    overrides: {
      MUIDataTable: {
        paper: {
          '& tbody tr.Mui-selected': {
            '&:hover': {
              backgroundColor: Colors.light2,
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        root: {
          ...cellStyle,
          fontWeight: 'bold',
        },
      },
      MUIDataTableBodyCell: {
        root: cellStyle,
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: 'hidden',
        },
      },
      MUIDataTableFilter: {
        root: {
          minWidth: 400,
        },
      },
      MuiTab: {
        root: {
          minWidth: `100px !important`,
        },
      },
    },
  });
};

export default function Table({
  title,
  description = '',
  data,
  columns,
  options,
  themeProps,
  onUpdateItem,
  onRefresh,
  onAddItem,
  onBatchAdd,
  // batchTemplateFields,
  isLoading,
  // nested = false,
  hide,
  editButton: EditButton,
  customButton,
  onRowSelectionChange = () => {},
  customToolbarSelect,
  customDownloadData,
}) {
  const classes = useStyles();
  const { appGroup } = useCache();

  const [updatedColumns, setUpdatedColumns] = useState(columns);
  const groupKey = appGroup === 'FacilityAdmins' ? '' : appGroup;
  const cachedVisibleColumnKey = `app:table:${groupKey}${title}${description}:visibleColumns`;
  const cachedPageKey = `app:table:${groupKey}${title}${description}:page`;
  const cachedRowsPerPageKey = `app:table:${groupKey}${title}${description}:RowsPerPage`;

  // const handleUpload = (event) => {
  //   const file = event.target.files[0];
  //   // global.logger.debug(file);

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     const options = {
  //       columns: true,
  //     };
  //     csv.parse(reader.result, options, (err, data) => {
  //       if (err) {
  //         global.logger.debug(err);
  //       } else
  //       if (onBatchAdd) {
  //         const updatedData = data.map((item) => {
  //           const newItem = {};
  //           columns
  //             .filter(({ isTemplate }) => isTemplate)
  //             .forEach(({ name, label, type }) => {
  //               newItem[name] = item[label];
  //               switch (type) {
  //               case 'number':
  //                 newItem[name] = parseInt(item[label]);
  //                 break;
  //               default:
  //                 newItem[name] = item[label];
  //               }
  //             });

  //           return newItem;
  //         });
  //         onBatchAdd(updatedData);
  //       }
  //     });
  //   };

  //   reader.readAsText(file, { encoding: 'utf8' });
  // };

  // overwrite options
  const updatedOptions = Object.assign({
    enableNestedDataAccess: '.',
    pagination: true,
    responsive: 'vertical', // nested ? 'vertical' : 'standard',
    tableBodyHeight: undefined, // nested ? undefined : 'calc(100vh - 223px)', // 183
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000],
    page: cache.get(cachedPageKey) || 0,
    rowsPerPage: cache.get(cachedRowsPerPageKey) || 10,
    filterType: 'checkbox',
    fixedHeader: true,
    resizableColumns: false,
    selectableRows: 'none',
    print: true,
    download: true,
    downloadOptions: {
      filename: `${title}.csv`,
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let updatedData = data;
      if (customDownloadData) {
        updatedData = customDownloadData(columns, data);
      }
      return '\uFEFF' + buildHead(columns) + buildBody(updatedData);
    },
    expandableRows: true,
    isRowExpandable: () => false,
    isRowSelectable: () => false,
    selectableRowsOnClick: false,
    customToolbarSelect: customToolbarSelect && ((selectedRows, displayData, setSelectedRows) =>
      customToolbarSelect({ onUpdate: onRefresh, selectedRows, setSelectedRows })),
    onRowSelectionChange,
    onRowClick: (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      global.logger.debug(item);
    },
    customToolbar: () =>
      <React.Fragment>
        {onRefresh &&
          <Tooltip title={'更新資料'}>
            <IconButton
              data-testid={'refresh-iconButton'}
              aria-label={'refresh'}
              onClick={onRefresh}>
              <SyncIcon />
            </IconButton>
          </Tooltip>}
        {onBatchAdd &&
          <React.Fragment>
            <Tooltip title={'下載批量模板 CSV'}>
              <IconButton
                data-testid={'batch-template-icon'}
                aria-label={'batch-teamplate'}
                data={[columns.filter(({ isTemplate })=>isTemplate).map(({ label }) => label)]}
                filename={`${title}-批量模板.csv`}
                component={CSVLink}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        }
        {/* {onBatchAdd &&
          <Tooltip title={'批量新增 (上傳CSV)'}>
            <IconButton
              data-testid={'batch-add-icon'}
              aria-label={'batch-add'}
              component="label"
            >
              <QueueIcon />
              <input
                type="file"
                style={{ display: 'none' }}
                accept=".csv"
                onChange={handleUpload}
              />
            </IconButton>
          </Tooltip>
        } */}
        {EditButton && <EditButton mode={'add'} onUpdate={onRefresh} />}
        {onAddItem &&
          <Tooltip title={'新增資料'}>
            <IconButton
              data-testid={'add-iconButton'}
              aria-label={'add'}
              onClick={onAddItem}>
              <AddIcon />
            </IconButton>
          </Tooltip>}
        {customButton && customButton({ data })}
      </React.Fragment>,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
      // 用onChangePage和onChangeRowsPerPage會有一些狀況會沒有被call, 無法正確cache, 所以在這裡cache
      cache.set(cachedPageKey, page);
      cache.set(cachedRowsPerPageKey, rowsPerPage);
      return (
        <Footer
          description={description}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={options && options.rowsPerPageOptions}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels} />
      );
    },
    textLabels: {
      body: {
        noMatch: '抱歉，找不到相關資料',
        toolTip: '排序',
        columnHeaderTooltip: (column) => `使用[${column.label}]排序`,
      },
      pagination: {
        next: '下一頁',
        previous: '上一頁',
        rowsPerPage: '每頁顯示',
        displayRows: '總項目數',
      },
      toolbar: {
        search: '搜尋',
        downloadCsv: '下載 CSV',
        print: '列印',
        viewColumns: '顯示欄位',
        filterTable: '篩選數據',
      },
      filter: {
        all: '全部',
        title: '篩選數據',
        reset: '重設',
      },
      viewColumns: {
        title: '欄位',
        titleAria: '顯示/隱藏欄位',
      },
      selectedRows: {
        text: '筆資料已選取',
      },
      // selectedRows: {
      //   text: 'row(s) selected',
      //   delete: 'Delete',
      //   deleteAria: 'Delete Selected Rows',
      // },
    },
    onViewColumnsChange(changedColumn, action) {
      global.logger.debug('onViewColumnsChange', changedColumn, action);
      const cacheVisiableColumns = (cache.get(cachedVisibleColumnKey) || '').split(',');
      const index = cacheVisiableColumns.indexOf(changedColumn);
      if (action === 'add' && index === -1) {
        cacheVisiableColumns.push(changedColumn);
      } else
      if (action === 'remove' && index !== -1) {
        cacheVisiableColumns.splice(index, 1);
      }

      cache.set(cachedVisibleColumnKey, cacheVisiableColumns.join(','));
    },
    onColumnViewChange: (changedColumn, action, ...args) => {
      global.logger.debug(changedColumn, action, args);
      const cacheVisiableColumns = (cache.get(cachedVisibleColumnKey) || '').split(',');
      const index = cacheVisiableColumns.indexOf(changedColumn);
      if (action === 'add' && index === -1) {
        cacheVisiableColumns.push(changedColumn);
      } else
      if (action === 'remove' && index !== -1) {
        cacheVisiableColumns.splice(index, 1);
      }

      cache.set(cachedVisibleColumnKey, cacheVisiableColumns.join(','));
    },
  }, options);

  useEffect(() => {
    const newColumns = deepcopy(columns);

    const cacheVisiableColumns = cache.get(cachedVisibleColumnKey) ? cache.get(cachedVisibleColumnKey).split(',') : undefined;
    const visibleColumns = [];

    if ((EditButton || onUpdateItem) && !newColumns.find(({ name }) => name === 'actions')) {
      newColumns.push({
        name: 'actions',
        label: ' ',
        type: 'actions',
        options: {
          display: true,
          filter: false,
          sort: false,
          download: false,
          print: false,
          viewColumns: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <div>
                {
                  EditButton ? <EditButton item={data[dataIndex]} onUpdate={onRefresh} />:
                    <IconButton
                      aria-label="edit"
                      size="small"
                      color={'inherit'}
                      onClick={() => {
                        if (onUpdateItem) {
                          onUpdateItem(data[dataIndex], dataIndex);
                        }
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                }
              </div>
            );
          },
        },
      });
    }

    newColumns
      .map((column, index) => {
        if (!Object.prototype.hasOwnProperty.call(column, 'options')) {
          column.options = {};
        }
        return column;
      })
      .forEach(({ name, type, options = {} }) => {
        switch (type) {
          case 'actions':
            break;
          case 'datetime':
            options.customBodyRender = (value) => {
              if (!value) return '';

              if (value === 'N/A') return '';

              if (Number.isInteger(value) && value < 9999999999) {
                value = value * 1000;
              }

              // if (!moment(value, 'DD-MM-YYYY', true).isValid()) return '';

              return (
                <div>
                  {moment(value).tz(TIME_ZONE).format('YYYY/MM/DD')} <br/>
                  {moment(value).tz(TIME_ZONE).format('HH:mm')}
                </div>);
            };
            break;
          case 'time':
            options.customBodyRender = (value) => {
              if (!value) return '';

              if (value === 'N/A') return '';

              if (Number.isInteger(value) && value < 9999999999) {
                value = value * 1000;
              }

              return moment(value).tz(TIME_ZONE).format('HH:mm');
            };
            break;
          case 'expirationUnixTime':
            options.customBodyRender = (value) => {
              if (!value) return '';

              if (value === 'N/A') return '';

              if (Number.isInteger(value) && value < 9999999999) {
                value = value * 1000;
              }

              // if (!moment(value, 'DD-MM-YYYY', true).isValid()) return '';

              return (
                <div>
                  {formatDatetimeFromNow(value)}
                </div>);
            };
            break;
          case 'id':
            options.customBodyRenderLite = (value) => {
              return (
                <CopyToClipboard
                  text={value}
                  onCopy={() => toastr.info('已複製至剪接簿')}
                >
                  <FileCopyIcon style={{ color: Colors.light, fontSize: 18, cursor: 'pointer', marginLeft: 8 }} />
                </CopyToClipboard>
              );
            };
            break;
          case 'checkbox':
            options.customBodyRender = (value) => {
              const isChecked = (value == 'true' || value === 'yes' || value === true || value === 1 || value) ? true : false; // eslint-disable-line eqeqeq
              return (<Checkbox checked={isChecked} color="default" disabled={true} />);
            };
            break;
          case 'number':
            options.customBodyRender = (val) => (
              <div className={classes.numberContainer}>
                {!isNaN(val) ? new Intl.NumberFormat().format(val) : 'N/A'}
              </div>
            );
            break;
          case 'currency':
            options.customBodyRender = (val) => (
              <div className={classes.numberContainer}>
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val).replace('.00', '')}
              </div>
            );
            break;
          case 'currency-cents':
            options.customBodyRender = (val) => (
              <div className={classes.numberContainer}>
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val/100).replace('.00', '')}
              </div>
            );
            break;
          case 'boolean':
            options.customBodyRender = (val) => val ? '是' : '否';
            break;
          case 'json':
            options.customBodyRender = (input) => {
              try {
                let jsonObj = input;
                while (typeof jsonObj === 'string') {
                  jsonObj = JSON.parse(jsonObj);
                }
                return <ReactJson
                  src={jsonObj}
                  name={false}
                  indentWidth={4}
                  collapsed={1}
                  enableClipboard={false}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />;
              } catch (e) {
                global.logger.debug(e);
                return '';
              }
            };
            break;
          default:
            options.customBodyRender = options.customBodyRender || ((val) => val ? val : null);
            break;
        }

        if (Array.isArray(hide) && hide.includes(name)) {
          options.display = false;
        }

        if (cacheVisiableColumns && options.display !== 'excluded') {
          options.display = cacheVisiableColumns.includes(name);
        }

        if (options.display !== false && options.display !== 'excluded') {
          visibleColumns.push(name);
        }
      });

    setUpdatedColumns(newColumns);
    cache.set(cachedVisibleColumnKey, visibleColumns.join(','));
  }, [columns, data, onUpdateItem, classes.numberContainer, hide, EditButton, cachedVisibleColumnKey]);

  return (
    <MuiThemeProvider theme={theme(themeProps)}>
      <div className={classes.container}>
        {isLoading &&
          <div className={classes.loadingContainer}>
            <CircularProgress size={36} />
          </div>}
        <MUIDataTable
          title={title}
          data={data}
          columns={updatedColumns}
          options={updatedOptions}
        />
      </div>
    </MuiThemeProvider>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  options: PropTypes.shape({
    display: PropTypes.bool,
    viewColumns: PropTypes.bool,
    customBodyRender: PropTypes.func,
    customBodyRenderLite: PropTypes.func,
    rowsPerPageOptions: PropTypes.any,
  }),
  nested: PropTypes.bool,
  themeProps: PropTypes.object,
  maxHeight: PropTypes.string,
  onUpdateItem: PropTypes.func,
  onRefresh: PropTypes.func,
  onAddItem: PropTypes.func,
  onBatchAdd: PropTypes.func,
  batchTemplateFields: PropTypes.array,
  isLoading: PropTypes.bool,
  hide: PropTypes.array,
  editButton: PropTypes.func,
  customButton: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  customToolbarSelect: PropTypes.func,
  customDownloadData: PropTypes.func,
};
