/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://uvmqufnaxfdknonohvhcxevgxe.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-veh4btbbbjh23gzmkl4h2vs5wq",
    "aws_cloud_logic_custom": [
        {
            "name": "webhook",
            "endpoint": "https://ez3wu2sqr5.execute-api.ap-southeast-1.amazonaws.com/develop",
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-1:e03943ef-512b-4908-9d66-af4b27291bee",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_mMCSO3JdB",
    "aws_user_pools_web_client_id": "4a5gum5u0rbacjahi6qk5bvve7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "silvergate639f5cfdeddb42f78ca0c4a2d0471c8f112227-develop",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
