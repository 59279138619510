import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { WEEKDAYS_SHORT } from '@silvergatedelivery/constants';
import moment from 'moment';

import DataTable from 'components/Table/DataTable';
import {
  listElders,
  // getEldersByClient,
  getEldersByCountyByStatus,
  getEldersByClientByStatus,
} from 'graphql/queries';
import {
  formatAddress,
  formatAddressLatLng,
  formatTags,
  formatAge,
  formatGender,
  formatNote,
} from 'utilities/format';
import ElderDetails from 'components/ElderDetails';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import ElderEditButton from 'forms/ElderForm/ElderEditButton';
import { counties } from '@silvergatedelivery/constants';
import cache from 'utilities/cache';
import { elderStatus } from '@silvergatedelivery/constants';
import { useCache } from 'CacheProvider';
import { sortBy } from 'utilities/sorting';

export default function ElderTable({
  title = i18n.t('送餐對象'),
  description,
  organizationId: inOrganizationId,
  clientId: inClientId,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();
  const [listQueries, setListQueries] = useState();
  const { appGroup, selectedUserGroupParams } = useCache();

  const organizationId = inOrganizationId || cache.get('app:organizationId');
  const adminMode = appGroup === 'Admins';
  const orgMode = appGroup === 'OrgAdmins';
  const facilityMode = appGroup === 'FacilityAdmins';

  const cachedClientId = cache.get('app:facilityId');
  const clientId = inClientId || cachedClientId;

  const options = {
    expandableRows: !orgMode,
    isRowExpandable: () => !orgMode,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns}>
          <ElderDetails id={id} isNested={true} />
        </NestedTableContainer>
      );
    },
  };

  const renderRepeatOn = (repeatOn) => {
    if (!repeatOn || repeatOn.length === 0) {
      return '';
    }
    return repeatOn.sort((a, b) => {
      if (a === 0) return 1; // move 0 to the last possition
      if (b === 0) return -1;
      return a - b;
    }).map((day) => `${WEEKDAYS_SHORT[day]}`).join('、');
  };

  const getDisadvantagedType = (disadvantagedTypesHistory) => {
    let type = '';
    if (disadvantagedTypesHistory) {
      const today = moment().format('YYYY-MM-DD');
      disadvantagedTypesHistory.forEach(({ disadvantagedTypes, approvedDate }) => {
        if (approvedDate <= today) {
          type = disadvantagedTypes;
        } else {
          return;
        }
      });
    }
    return type;
  };

  const columns = [
    {
      name: 'id',
      label: '編號',
      isTemplate: true,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'clientId',
      label: '客戶編號',
      options: {
        display: 'excluded',
        filter: false,
        sort: true,
      },
      hideInFacilityMode: true,
    },
    {
      name: 'status',
      label: '狀態',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'username',
      label: '帳號',
      isTemplate: true,
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInFacilityMode: true,
      hideInOrgMode: true,
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        // filterList: clientId ? [] : [cache.get('app:location')].filter((x) => x),
        filter: false,
        sort: true,
      },
      hideInFacilityMode: true,
    },
    {
      name: 'client.name',
      label: adminMode ? '機構名稱/客戶姓名' : '機構',
      options: {
        filter: true,
        sort: true,
      },
      hideInFacilityMode: true,
    },
    {
      name: 'name',
      label: '名字',
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender(name, tableMeta) {
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (orgMode && organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              return name[0] + '姓長輩';
            }
          }
          return name;
        },
      },
    },
    {
      name: 'nickname',
      label: '暱稱',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'serviceCode',
      label: '服務代碼',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'identificationCardId',
      label: '身分證字號',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender(identificationCardId, tableMeta) {
          if (!identificationCardId) return '';
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (orgMode && organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              const prefix = identificationCardId.slice(0, 3);
              const maskedPart = identificationCardId.slice(3).replace(/./g, '*');
              return prefix + maskedPart;
            }
          }
          return identificationCardId;
        },
      },
    },
    {
      name: 'caseNumber',
      label: '案號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'birthday',
      label: '年齡',
      options: {
        display: false,
        filter: true,
        sort: true,
        customBodyRender: (birthday) => formatAge(birthday),
        filterOptions: {
          names: ['大於等於65歲', '小於65歲'],
          logic(birthday, filterVal) {
            const age = formatAge(birthday);
            if (filterVal.includes('大於等於65歲') && filterVal.includes('小於65歲')) {
              return false;
            } else if (filterVal.includes('大於等於65歲')) {
              return age < 65;
            } else if (filterVal.includes('小於65歲')) {
              return age >= 65;
            }
            return false;
          },
        },
      },
    },
    {
      name: 'gender',
      label: '性別',
      options: {
        display: false,
        filter: true,
        sort: true,
        customBodyRender: formatGender,
      },
    },
    {
      name: 'phoneNumber',
      label: '電話',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender(phone, tableMeta) {
          if (!phone) return '';
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (orgMode && organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              const prefix = phone.slice(0, 3);
              const maskedPart = phone.slice(3).replace(/./g, '*');
              return prefix + maskedPart;
            }
          }
          return phone;
        },
      },
    },
    {
      name: 'phoneNumber2',
      label: '電話2',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'phoneNumber3',
      label: '電話3',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'address',
      label: '地址',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: (address, tableMeta) => {
          let value = formatAddress(address);
          if (!value) return '';
          const clientId = tableMeta.rowData[1];
          const { organizationData } = selectedUserGroupParams;
          if (orgMode && organizationData?.clients?.items) {
            const clientParams = organizationData?.clients?.items.find((data) => data.clientId === clientId);
            if (clientParams && clientParams.visitorMode) {
              const prefix = value.slice(0, 7);
              const maskedPart = value.slice(7).replace(/./g, '*');
              value = prefix + maskedPart;
            }
          }
          return value;
        },
      },
    },
    {
      name: 'address',
      label: '地址經緯度',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatAddressLatLng,
      },
      hideInOrgMode: true,
    },
    {
      name: 'lunchRepeatOn',
      label: '午餐送餐週期',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: renderRepeatOn,
      },
    },
    {
      name: 'dinnerRepeatOn',
      label: '晚餐送餐週期',
      edit: {
        type: 'text',
      },
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: renderRepeatOn,
      },
    },
    {
      name: 'client.phoneNumber',
      label: adminMode ? '機構/客戶電話' : '機構電話',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
      hideInFacilityMode: true,
    },
    {
      name: 'noteForHealth',
      label: '健康備註',
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: formatNote,
      },
    },
    {
      name: 'noteForDelivery',
      label: `${t('送餐')}備註`,
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatNote,
      },
    },
    {
      name: 'noteForMeal',
      label: `${t('餐點')}備註`,
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatNote,
      },
    },
    {
      name: 'note',
      label: '備註',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatNote,
      },
    },
    {
      name: 'govQualifiedCount',
      label: '政府核定量',
      type: 'number',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'govQualifiedPrice',
      label: '政府核定單價',
      type: 'number',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'supervisorId',
      label: '督導ID',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'linkGroupName',
      label: '家庭代碼',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'otherSocialServices',
      label: '其它社會資源協助',
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: formatNote,
      },
    },
    {
      name: 'tags',
      label: '標籤',
      options: {
        filter: false,
        sort: false,
        customBodyRender: formatTags,
      },
    },
    {
      name: 'isDisadvantaged',
      label: '弱勢長輩(非自費戶)',
      type: 'checkbox',
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: 'disadvantagedTypes',
    //   label: '弱勢身份別(舊)',
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: 'disadvantagedTypesHistory',
      label: '弱勢身份別',
      options: {
        display: false,
        filter: true,
        sort: true,
        customBodyRender(value) {
          return getDisadvantagedType(value);
        },
        sortCompare(order) {
          return ({ data: value1 }, { data: value2 }) => {
            const type1 = getDisadvantagedType(value1);
            const type2 = getDisadvantagedType(value2);
            return (type1 > type2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      name: 'disabilityLevel',
      label: '身障等級',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cmsLevel',
      label: '失能等級',
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'isAllowedToEnterHome',
      label: '是否同意進入長輩家中',
      type: 'checkbox',
      options: {
        filter: false,
        sort: true,
      },
      hideInOrgMode: true,
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/elder/${id}`}
              label={`前往${t('送餐對象')}頁面`}
            />
          );
        },
      },
      hideInOrgMode: true,
    },
  ].filter(({ hideInFacilityMode, hideInOrgMode }) => !(facilityMode && hideInFacilityMode) && !(orgMode && hideInOrgMode));

  useEffect(() => {
    if (organizationId) {
      const { organizationClientIds } = selectedUserGroupParams;

      setListQueries(organizationClientIds.map((clientId) => {
        return {
          name: getEldersByClientByStatus,
          params: {
            clientId,
            status: { eq: '使用中' },
          },
        };
      }));

      setIndexes(undefined);

      return;
    }

    if (clientId) {
      setIndexes([
        {
          name: 'byClientByStatus',
          label: '按狀態查詢',
          partitionKey: 'clientId',
          sortKey: 'status',
          fields: [
            // {
            //   label: '客戶編號',
            //   key: 'clientId',
            // },
            {
              label: '狀態',
              key: 'status',
              type: 'select',
              options: ['使用中', '已停用'].map((item) => {
                return { value: item, label: item };
              }),
            },
          ],
          operation: getEldersByClientByStatus,
        },
      ]);

      setQueryParams({
        clientId,
        status: '使用中',
      });

      return;
    }

    setIndexes([
      {
        name: 'byCountyByStatus',
        label: '按地區查詢',
        partitionKey: 'county',
        sortKey: 'status',
        fields: [
          {
            label: '縣市',
            key: 'county',
            type: 'select',
            options: counties.map((item) => {
              return { value: item, label: item };
            }),
            size: 6,
          },
          {
            label: '使用狀態',
            key: 'status',
            type: 'select',
            options: [
              { value: undefined, label: '全部' },
              ...elderStatus,
            ],
            size: 6,
          },
          {
            label: '姓名',
            key: 'name',
            comparisonOperator: 'contains',
          },
        ],
        operation: getEldersByCountyByStatus,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [
          {
            label: '姓名',
            key: 'name',
            comparisonOperator: 'contains',
          },
          // {
          //   label: '創立時間',
          //   key: 'createdAt',
          //   type: 'datetime',
          // },
        ],
        operation: listElders,
      },
    ]);

    setQueryParams({
      county: cache.get('app:location') || counties[0],
      status: '使用中',
    });
  }, [organizationId, clientId]);

  const customDownloadData = (columns, data) => {
    const tagIndex = columns.findIndex(({ name }) => name === 'tags');
    if (tagIndex !== -1) {
      return data.map((value) => {
        if (value?.data[tagIndex]?.items) {
          value.data[tagIndex] = value.data[tagIndex].items
            .map(({ tag }) => tag)
            .sort(sortBy('name'))
            .sort(sortBy('subcategory'))
            .sort(sortBy('category'))
            .map((tag) => tag.label).join('、');
        }
        return value;
      });
    }
    return data;
  };

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        listQueries={listQueries}
        editButton={ElderEditButton}
        customDownloadData={customDownloadData}
        {...props}
      />
    </React.Fragment>
  );
}

ElderTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  organizationId: PropTypes.string,
  clientId: PropTypes.string,
};
