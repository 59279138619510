import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { useCache } from 'CacheProvider';

import {
  counties,
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import { asyncListAll, request } from 'utilities/graph';

import writeVulnerable from './writeVulnerable';

import {
  getEldersByServiceCodeByStatus,
  getEldersByClientByServiceCode,
  getOrdersByElderByDate,
  getPandagoOrdersByOrderByCreatedAt,
} from './queries';

import { processShareOrder, processDisadvantagedTypes } from './CountyPrsReport/workbooks/helpers';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

export default function VulnerableReport({ clientOptions: inClientOptions = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  const [includeInactiveElders, setIncludeInactiveElders] = useState(false);
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState();
  const [selectedCounty, setSelectedCounty] = useState(counties[0]);
  const [clientOptions, setClientOptions] = useState([]);
  const { isAdmin, facilityIds, appGroup } = useCache();
  const facilityMode = appGroup === 'FacilityAdmins';

  const updateCounty = (client) => {
    if (client && client.county) {
      const targetCounty = counties.find((county) => county === client.county);
      if (targetCounty) {
        setSelectedCounty(targetCounty);
      }
    }
  };

  useEffect(() => {
    const clientOptions = JSON.parse(JSON.stringify(inClientOptions));
    if ((!facilityMode && inClientOptions.length > 1 && inClientOptions[0].id !== 'N/A') ||
      (facilityMode && facilityIds.length > 1)) {
      clientOptions.unshift({
        id: 'N/A',
        name: '全部機構',
      });
    }

    setClientOptions(clientOptions);
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
      updateCounty(clientOptions[0]);
    }
  }, [inClientOptions]);


  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    const period = `${fromDate}__${toDate}`;

    const {
      name,
      facilitySettings: { dba } = {},
    } = clientOptions.find(({ id }) => id === selectedClientId) || {};

    const clientName = dba || name || '全部機構';

    global.logger.debug({
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    let allElders = [];
    if (selectedClientId && selectedClientId !== 'N/A') {
      allElders = await asyncListAll(getEldersByClientByServiceCode, {
        clientId: selectedClientId,
        serviceCode: { eq: '脆家' },
        limit: 1000,
      });
    } else {
      if (facilityMode && facilityIds.length !== 0) {
        allElders = (await Promise.all(facilityIds.map(async (facilityId) =>
          await asyncListAll(getEldersByClientByServiceCode, {
            clientId: facilityId,
            serviceCode: { eq: '脆家' },
            limit: 1000,
          })))
        ).flat();
      } else if (isAdmin) {
        allElders = await asyncListAll(getEldersByServiceCodeByStatus, {
          serviceCode: '脆家',
          status: includeInactiveElders ? undefined : { eq: '使用中' },
          limit: 1000,
        });
      }
    }

    let elders = allElders;
    if (selectedCounty) {
      elders = elders.filter(({ county }) => county === selectedCounty);
    }

    const orders = [].concat(...await Promise.all(elders.map(({ id }) =>
      asyncListAll(getOrdersByElderByDate, {
        elderId: id,
        date: {
          between: [fromDate, toDate],
        },
        limit: 1000,
      }))));

    const deliveryStaffIds = [];
    const restaurantIds = [];
    orders.forEach(({ deliveryStaffId, restaurantId }) => {
      if (deliveryStaffId && !deliveryStaffIds.includes(deliveryStaffId)) {
        deliveryStaffIds.push(deliveryStaffId);
      }
      if (restaurantId && !restaurantIds.includes(restaurantId)) {
        restaurantIds.push(restaurantId);
      }
    });

    let pandagoDeliveryStaffId;
    const deliveryStaffs = await Promise.all(deliveryStaffIds.map(async (id) => {
      const { data: { getDeliveryStaff: data } } = await request( /* GraphQL */ `
        query GetDeliveryStaff($id: ID!) {
          getDeliveryStaff(id: $id) {
            id
            name
            username
            identificationCardId
          }
        }
      `, { id });

      if (data.username === 'pandago') {
        pandagoDeliveryStaffId = id;
      }

      return data;
    }));
    const restaurants = await Promise.all(restaurantIds.map(async (id) => {
      const { data: { getRestaurant: data } } = await request( /* GraphQL */ `
          query GetRestaurant($id: ID!) {
            getRestaurant(id: $id) {
            id
            name
          }
        }
      `, { id });

      return data;
    }));

    const pandagoOrders = await Promise.all(orders
      .filter(({ deliveryStaffId }) => deliveryStaffId === pandagoDeliveryStaffId)
      .map(async ({ id: orderId }) => {
        const [pandagoOrder] = await asyncListAll(getPandagoOrdersByOrderByCreatedAt, {
          orderId,
          limit: 1,
        });

        return pandagoOrder;
      }));

    processShareOrder(elders, orders);
    processDisadvantagedTypes(selectedMonth, elders);
    writeVulnerable(
      { elders,
        orders, deliveryStaffs, pandagoOrders, selectedMonth, clientName, includeUndeliveredOrders, excludeNonFoodDeliveryOrders,
        county: selectedCounty,
        restaurants,
      },
    );
  };

  const triggerDownload = async () => {
    try {
      setMessage('');
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>脆弱家庭核銷報表</h3>
      <div>
        <FormControl style={{ minWidth: 360, marginRight: 16 }}>
          <AutoSelect
            id="selected-client"
            options={clientOptions.map(({ name }) => name)}
            values={clientOptions.map(({ id }) => id)}
            required={true}
            value={selectedClientId}
            label="機構"
            onChange={(value) => {
              setSelectedClientId(value);
              if (value) {
                const client = clientOptions.find(({ id }) => id === value);
                updateCounty(client);
              }
            }}
          />
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-month">月份</InputLabel>
          <Select
            labelId="selected-month-label"
            id="selected-month"
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value)}
            disabled={isLoading}
          >
            {monthOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-county">縣市</InputLabel>
          <Select
            labelId="selected-county-label"
            id="selected-county"
            value={selectedCounty}
            onChange={(event) => setSelectedCounty(event.target.value)}
            disabled={isLoading}
          >
            {counties.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeInactiveElders}
              name={'includeInactiveElders'}
              value={includeInactiveElders}
              disabled={isLoading}
              onChange={(e) => {
                setIncludeInactiveElders(e.target.checked);
              }}
            />
          }
          label={'包含已停用的個案'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeUndeliveredOrders}
              name={'includeUndeliveredOrders'}
              value={includeUndeliveredOrders}
              disabled={isLoading}
              onChange={(e) => {
                setIncludeUndeliveredOrders(e.target.checked);
              }}
            />
          }
          label={'包含未送達的訂單'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excludeNonFoodDeliveryOrders}
              name={'excludeNonFoodDeliveryOrders'}
              value={excludeNonFoodDeliveryOrders}
              disabled={isLoading}
              onChange={(e) => {
                setExcludeNonFoodDeliveryOrders(e.target.checked);
              }}
            />
          }
          label={'排除非送餐的訂單'}
        />
      </div>

      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}

VulnerableReport.propTypes = {
  clientOptions: PropTypes.array,
};
