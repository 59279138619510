import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment-timezone';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';

import {
  counties,
  TIME_ZONE,
} from '@silvergatedelivery/constants';
import { asyncListAll, request } from 'utilities/graph';

import issueReceipts from './issueReceipts';

import {
  getEldersByClientByStatus,
  getOrdersByClientByDate,
  getPandagoOrdersByOrderByCreatedAt,
} from '../queries';
import { getClient } from 'graphql/queries';

import { processShareOrder } from '../CountyPrsReport/workbooks/helpers';

const monthOptions = Array(10).fill('').map((x, index) => {
  return moment().add(-index, 'months').format('YYYY-MM');
});

export default function SelfPayingReceipt({ clientOptions = [] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);
  const [selectedCounty, setSelectedCounty] = useState(counties[0]);
  const [selectedClientId, setSelectedClientId] = useState();
  const [includeInactiveElders, setIncludeInactiveElders] = useState(false);
  const [includeUndeliveredOrders, setIncludeUndeliveredOrders] = useState(false);
  const [excludeNonFoodDeliveryOrders, setExcludeNonFoodDeliveryOrders] = useState(false);

  const collectData = async () => {
    const from = moment(selectedMonth).tz(TIME_ZONE).startOf('month');
    const to = moment(selectedMonth).tz(TIME_ZONE).endOf('month');
    const fromDate = from.format('YYYY-MM-DD');
    const toDate = to.format('YYYY-MM-DD');
    const fromISOString = from.toISOString();
    const toISOString = to.toISOString();
    // const monthString = from.format('YYYY/MM');
    const period = `${fromDate}__${toDate}`;
    const { id: clientId } = clientOptions.find(({ id }) => id === selectedClientId);

    global.logger.debug({
      selectedClientId,
      period,
      selectedMonth,
      fromDate,
      toDate,
      fromISOString,
      toISOString,
    });

    const [
      elders,
      orders,
      { data: { getClient: client } },
    ] = await Promise.all([
      asyncListAll(getEldersByClientByStatus, {
        clientId,
        status: includeInactiveElders ? undefined : { eq: '使用中' },
        filter: {
          isDisadvantaged: {
            eq: 0,
          },
        },
        limit: 1000,
      }),
      asyncListAll(getOrdersByClientByDate, {
        clientId,
        date: {
          between: [fromDate, toDate],
        },
        limit: 1000,
      }),
      request(getClient, { id: clientId }),
    ]);

    const selfPayingOrders = orders.filter(({ elderId }) => elders.findIndex(({ id }) => id === elderId) !== -1);

    const deliveryStaffIds = [];
    selfPayingOrders.forEach(({ deliveryStaffId }) => {
      if (deliveryStaffId && !deliveryStaffIds.includes(deliveryStaffId)) {
        deliveryStaffIds.push(deliveryStaffId);
      }
    });

    let pandagoDeliveryStaffId;
    const deliveryStaffs = await Promise.all(deliveryStaffIds.map(async (id) => {
      const { data: { getDeliveryStaff: data } } = await request( /* GraphQL */ `
        query GetDeliveryStaff($id: ID!) {
          getDeliveryStaff(id: $id) {
            id
            name
            username
            identificationCardId
          }
        }
      `, { id });

      if (data.username === 'pandago') {
        pandagoDeliveryStaffId = id;
      }

      return data;
    }));

    const pandagoOrders = await Promise.all(selfPayingOrders
      .filter(({ deliveryStaffId }) => deliveryStaffId === pandagoDeliveryStaffId)
      .map(async ({ id: orderId }) => {
        const [pandagoOrder] = await asyncListAll(getPandagoOrdersByOrderByCreatedAt, {
          orderId,
          limit: 1,
        });

        return pandagoOrder;
      }));

    processShareOrder(elders, selfPayingOrders);

    issueReceipts({
      county: selectedCounty,
      elders,
      orders: selfPayingOrders,
      deliveryStaffs, pandagoOrders, selectedMonth, client, includeUndeliveredOrders, excludeNonFoodDeliveryOrders,
    });
  };

  const triggerDownload = async () => {
    try {
      setIsLoading(true);
      await collectData();
    } catch (e) {
      global.logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };


  const updateCounty = (client) => {
    if (client && client.county) {
      const targetCounty = counties.find((county) => county === client.county);
      if (targetCounty) {
        setSelectedCounty(targetCounty);
      }
    }
  };

  useEffect(() => {
    if (clientOptions[0]) {
      setSelectedClientId(clientOptions[0].id);
      updateCounty(clientOptions[0]);
    }
  }, [clientOptions]);

  return (
    <div>
      <h3>餐飲服務費收據(自費戶)</h3>
      <div>
        <FormControl style={{ minWidth: 360, marginRight: 16 }}>
          <AutoSelect
            id="selected-restaurant"
            options={clientOptions.map(({ name }) => name)}
            values={clientOptions.map(({ id }) => id)}
            required={true}
            value={selectedClientId}
            label="機構"
            onChange={(value) => {
              setSelectedClientId(value);
              if (value) {
                const client = clientOptions.find(({ id }) => id === value);
                updateCounty(client);
              }
            }}
          />
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-month">月份</InputLabel>
          <Select
            labelId="selected-month-label"
            id="selected-month"
            value={selectedMonth}
            onChange={(event) => setSelectedMonth(event.target.value)}
            disabled={isLoading}
          >
            {monthOptions.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 150, marginRight: 16 }}>
          <InputLabel id="selected-county">縣市</InputLabel>
          <Select
            labelId="selected-county-label"
            id="selected-county"
            value={selectedCounty}
            onChange={(event) => setSelectedCounty(event.target.value)}
            disabled={isLoading}
          >
            {counties.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeInactiveElders}
              name={'includeInactiveElders'}
              value={includeInactiveElders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeInactiveElders(e.target.checked);
              }}
            />
          }
          label={'包含已停用的個案'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={includeUndeliveredOrders}
              name={'includeUndeliveredOrders'}
              value={includeUndeliveredOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setIncludeUndeliveredOrders(e.target.checked);
              }}
            />
          }
          label={'包含未送達的訂單'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={excludeNonFoodDeliveryOrders}
              name={'excludeNonFoodDeliveryOrders'}
              value={excludeNonFoodDeliveryOrders}
              disabled={!selectedClientId || isLoading}
              onChange={(e) => {
                setExcludeNonFoodDeliveryOrders(e.target.checked);
              }}
            />
          }
          label={'排除非送餐的訂單'}
        />
      </div>

      <Button
        variant="outlined"
        onClick={triggerDownload}
        disabled={!selectedClientId || isLoading}
        style={{ marginTop: 16 }}
      >
        下載
        {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
      </Button>
    </div>
  );
}

SelfPayingReceipt.propTypes = {
  clientOptions: PropTypes.array,
};
