import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DataTable from 'components/Table/DataTable';
import {
  listRestaurantOwners,
  getRestaurantOwnersByCountyByCreatedAt,
} from 'graphql/queries';
import { formatAddress } from 'utilities/format';
import RestaurantOwnerDetails from 'components/RestaurantOwnerDetails';
import NestedTableContainer from 'components/Table/NestedTableContainer';
import LinkButton from 'components/Table/LinkButton';
import RestaurantOwnerEditButton from 'forms/RestaurantOwnerForm/RestaurantOwnerEditButton';
import {
  counties,
} from '@silvergatedelivery/constants';
import cache from 'utilities/cache';

export default function RestaurantOwnerTable({
  title = `${i18n.t('餐廳')}負責人`,
  description,
  ...props
}) {
  const { t } = useTranslation();
  const [indexes, setIndexes] = useState();
  const [queryParams, setQueryParams] = useState();

  const clientId = cache.get('app:facilityId');

  const options = {
    expandableRows: true,
    isRowExpandable: () => true,
    renderExpandableRow(rowData) {
      const id = rowData[0];
      return (
        <NestedTableContainer columns={columns}>
          <RestaurantOwnerDetails id={id} isNested={true} />
        </NestedTableContainer>
      );
    },
  };
  const columns = [
    { name: 'id', label: 'ID', options: { display: false, filter: false, sort: false } },
    {
      name: 'id',
      label: '編號',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'county',
      label: '縣市',
      options: {
        // filterList: [cache.get('app:location')].filter((x) => x),
        filter: true,
        sort: true,
      },
    },
    {
      name: 'isActive',
      label: '使用中',
      type: 'checkbox',
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: '名字',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'username',
      label: '帳號',
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phoneNumber',
      label: '電話',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ wordBreak: 'break-all' }}>
            {value}
          </div>
        ),
      },
    },
    {
      name: 'address',
      label: '地址',
      options: {
        filter: false,
        sort: true,
        customBodyRender: formatAddress,
      },
    },
    {
      name: 'createdBy',
      label: '創立者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedBy',
      label: '更新者',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: '創立於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'updatedAt',
      label: '更新於',
      type: 'datetime',
      options: {
        display: false,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        display: true,
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRender(id) {
          return (
            <LinkButton
              path={`/restaurantOwner/${id}`}
              label={`前往${t('餐廳')}負責人頁面`}
            />
          );
        },
      },
    },
  ];

  useEffect(() => {
    // not enabled for client access
    if (clientId) {
      return;
    }
    setIndexes([
      {
        name: 'getRestaurantOwnersByCountyByCreatedAt',
        label: '按地區查詢',
        partitionKey: 'county',
        sortKey: 'createdAt',
        fields: [{
          label: '縣市',
          key: 'county',
          type: 'select',
          options: counties.map((item) => {
            return { value: item, label: item };
          }),
        }],
        operation: getRestaurantOwnersByCountyByCreatedAt,
      },
      {
        name: 'scan',
        label: '掃描全部資料',
        fields: [],
        operation: listRestaurantOwners,
      },
    ]);
    setQueryParams({
      county: cache.get('app:location') || counties[0],
    });
  }, []);

  if (!queryParams) return null;

  return (
    <React.Fragment>
      <DataTable
        title={title}
        description={description}
        columns={columns}
        options={options}
        indexes={indexes}
        queryDefaultParams={queryParams}
        editButton={RestaurantOwnerEditButton}
        // listQuery={listQuery}
        {...props}
      />
    </React.Fragment>
  );
}

RestaurantOwnerTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
