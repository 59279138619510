import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Semaphore } from 'async-mutex';
import FormDialog from 'components/FormDialog';
// import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { utils, writeFile } from 'xlsx';

import { Colors } from '@silvergatedelivery/constants';
import { request, asyncListAll } from 'utilities/graph';
import { adminCreateOrder, placeMultiStopOrder, quoteMultiStopOrder } from 'graphql/mutations';
import AutoSelect from './AutoSelect';
import { formatDatetime } from 'utilities/format';

import { getConfigurationByCategoryByStatus } from 'graphql/queries';
import { toastr } from 'react-redux-toastr';

import {
  getDeliveryGroupsByClientByIsActive,
  getElderDeliveryGroupsByClientByStatus,
} from './queries';

import {
  getRestaurantIdSchema,
  getClientIdSchema,
  getElderIdSchema,
  getDeliveryStaffIdSchema,
} from '../schemas';
import { sortBy } from 'utilities/sorting';
import OrderBatchForm from './OrderBatchForm';
import cache from 'utilities/cache';
import moment from 'moment-timezone';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { chunkArray } from 'utilities/array';

export default function AdminCreateBulkOrderForm({ onComplete, ...props }) {
  const { t } = useTranslation();
  const [allFormData, setAllFormData] = useState();
  const [message, setMessage] = useState();
  const [targetClientId, setTargetClientId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [restaurantOptions, setRestaurantOptions] = useState();
  const [clientOptions, setClientOptions] = useState();
  const [elderOptions, setElderOptions] = useState();
  const [deliveryGroupOptions, setDeliveryGroupOptions] = useState();
  const [deliveryStaffOptions, setDeliveryStaffOptions] = useState();
  const [elderDeliveryGroups, setElderDeliveryGroups] = useState([]);
  const [orderConfigurations, setOrderConfigurations] = useState([]);
  const [onlyShowFacilityCountyRestaurant, setOnlyShowFacilityCountyRestaurant] = useState(true);
  const [onlyShowFacilityRestaurant, setOnlyShowFacilityRestaurant] = useState(true);
  const [onlyShowFacilityDeliveryStaff, setOnlyShowFacilityDeliveryStaff] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [unlockDates, setUnlockDates] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [toCreateOrders, setToCreateOrders] = useState([]);
  const [createResults, setCreateResults] = useState([]);
  const [createErrors, setCreateErrors] = useState([]);
  const [createMultiStopOrderResults, setCreateMultiStopOrderResults] = useState([]);
  const [multiStopOrders, setMultiStopOrders] = useState({});
  const [lalamoveMultiStopDeliveryId, setLalamoveMultiStopDeliveryId] = useState();
  const [lalamoveMultiStopOrdersQuoteResult, setLalamoveMultiStopOrdersQuoteResult] = useState([]);

  const validate = () => {
    const noIds = allFormData.some((data) => {
      return data.orders.some((order) => !order.elderId);
    });

    if (noIds) {
      setMessage(`請檢查每個${t('餐廳')}訂單是否有選取${t('送餐對象')}`);
      return false;
    }

    const noMealItems = allFormData.some((data) => {
      return data.orders.some(({ restaurantId, mealItemName, mealItemQuantity }) => !restaurantId || !mealItemName || !mealItemQuantity);
    });

    if (noMealItems) {
      setMessage(`請檢查每個${t('送餐對象')}是否有${t('餐廳')}與餐點`);
      return false;
    }

    const noCostCOdItems = allFormData.some((data) => {
      return data.orders.some(({ paymentMethod, mealItemPrice }) => paymentMethod === 'CASH_ON_DELIVERY' && mealItemPrice === 0);
    });

    if (noCostCOdItems) {
      setMessage('到府收款 餐點單價須大於 $0');
      return false;
    }

    const duplicateElders = allFormData.some((data) => {
      const mappings = {};
      return data.orders.some(({ elderId }) => {
        if (mappings[elderId]) {
          return true;
        } else {
          mappings[elderId] = true;
          return false;
        }
      });
    });

    if (duplicateElders) {
      setMessage(`${t('餐廳')}訂單有重複的${t('送餐對象')}`);
      return false;
    }

    const noDeliveryDateTime = allFormData.some((data) => {
      return data.orders.some((order) => order.lunchs.length === 0 && order.dinners.length === 0);
    });

    if (noDeliveryDateTime) {
      setMessage('請檢查每位訂單是否已選取午晚餐');
      return false;
    }

    const invalidQuantity = allFormData.some((data) => {
      return data.orders.some(({ mealItemQuantity }) => isNaN(mealItemQuantity) || mealItemQuantity <= 0);
    });

    if (invalidQuantity) {
      setMessage('請檢查每位訂單是否設定餐點數量');
      return false;
    }

    const invalidCost = allFormData.some((data) => {
      return data.orders.some(({ mealItemCost }) => isNaN(mealItemCost) || mealItemCost < 0);
    });

    if (invalidCost) {
      setMessage('請檢查每位訂單是否設定餐點進價');
      return false;
    }

    const invalidPrice = allFormData.some((data) => {
      return data.orders.some(({ mealItemPrice }) => isNaN(mealItemPrice) || mealItemPrice < 0);
    });

    if (invalidPrice) {
      setMessage('請檢查每位訂單是否設定餐點單價');
      return false;
    }

    const invalidMultiStopOrderWithDifferentRestaurant = allFormData.some((data) => {
      if (data.orders[0].deliveryStaffId === lalamoveMultiStopDeliveryId) {
        return data.orders.some(({ restaurantId }) => restaurantId !== data.orders[0].restaurantId);
      } else {
        return false;
      }
    });

    if (invalidMultiStopOrderWithDifferentRestaurant) {
      setMessage('請檢查多點配送訂單的餐廳是否一致');
      return false;
    }

    const invalidDeliveryStaff = allFormData.some((data) => {
      return data.orders.some(({ deliveryStaffId }) => deliveryStaffId && !deliveryStaffOptions.enum.includes(deliveryStaffId));
    });

    if (invalidDeliveryStaff) {
      setMessage('訂單所指定的送餐大使不存在');
      return false;
    }

    const invalidRestaurant = allFormData.some((data) => {
      return data.orders.some(({ restaurantId }) => !restaurantOptions.find((restaurantOption) => restaurantOption.id === restaurantId));
    });

    if (invalidRestaurant) {
      setMessage('請檢查每位訂單的餐廳是否填寫正確');
      return false;
    }

    setMessage(null);
    return true;
  };

  const getDateAndMealSlot = (inDatetime) => {
    const entity = moment(inDatetime).tz(TIME_ZONE);
    const date = entity.format('YYYY-MM-DD');
    const hour = parseInt(entity.format('H'));
    const mealSlot = hour <= 14 ? 'lunch' : 'dinner';
    return {
      date,
      mealSlot,
    };
  };

  const checkLalamoveMultiStopOrderQuotation = async () => {
    if (!lalamoveMultiStopDeliveryId) {
      return true;
    }
    const toVerifyLalamoveOrders = [];
    allFormData.forEach(({ orders }, index) => {
      if (orders[0].deliveryStaffId === lalamoveMultiStopDeliveryId) {
        toVerifyLalamoveOrders.push({
          restaurantId: orders[0].restaurantId,
          elderIds: orders.map(({ elderId }) => elderId),
          formIndex: index,
        });
      }
    });
    if (toVerifyLalamoveOrders.length === 0) {
      return true;
    }

    setLalamoveMultiStopOrdersQuoteResult(toVerifyLalamoveOrders);

    await Promise.all(toVerifyLalamoveOrders.map((lalamoveOrder) => (async () => {
      const { restaurantId, elderIds } = lalamoveOrder;
      const quoteResult = await request(quoteMultiStopOrder, { input: { restaurantId, elderIds } });
      lalamoveOrder.result = quoteResult.data.quoteMultiStopOrder.data;
    })(),
    ));

    if (toVerifyLalamoveOrders.some(({ result }) => result.code !== 'SUCCESS')) {
      return false;
    }
    setLalamoveMultiStopOrdersQuoteResult(toVerifyLalamoveOrders);
    return true;
  };

  const handleSubmit = async () => {
    global.logger.debug(JSON.stringify(allFormData, null, 2));

    const isValidated = validate();
    if (!isValidated) return;

    let results = [];
    let multiStopOrderResults = [];
    const errors = [];
    const groupedMultiStopOrders = {};
    try {
      setMessage('上傳中，請稍候');
      setIsLoading(true);
      setOpenConfirmDialog(true);

      if (!await checkLalamoveMultiStopOrderQuotation()) {
        throw new Error('請確認餐廳與送餐地址是否位於服務範圍內');
      }

      const toCreateOrders = [];
      allFormData.forEach(({ orders }, index) => {
        orders.forEach(({
          elderId,
          restaurantId,
          deliveryStaffId,
          deliveryStaffFee,
          noteForDelivery,
          noteForMeal,
          category,
          paymentMethod,
          lunchs,
          dinners,
          deliveryGroupId,
          deliveryGroupSortOrder,
          mealItemName,
          mealItemQuantity,
          mealItemPrice,
          mealItemCost,
          mealItemNote,
          mealItemSurcharges,
        }) => {
          const allDeliveryDatetimes = [...lunchs, ...dinners];
          const quantity = mealItemQuantity || 1;
          const price = mealItemPrice || 0;
          const cost = mealItemCost || 0;
          const total = price * quantity;
          const totalCost = cost * quantity;
          // max 12 orders in one command
          const chunkDeliveryDatetimes = chunkArray(allDeliveryDatetimes, 12);
          chunkDeliveryDatetimes.forEach((deliveryDatetimes) => {
            toCreateOrders.push({
              restaurantId,
              elderId,
              deliveryDatetimes,
              noteForDelivery,
              noteForMeal,
              total,
              totalCost,
              deliveryStaffId: lalamoveMultiStopDeliveryId && deliveryStaffId === lalamoveMultiStopDeliveryId ? undefined: deliveryStaffId,
              deliveryStaffFee: deliveryStaffFee || 0,
              mealItems: [{
                name: mealItemName,
                quantity,
                price,
                cost,
                note: mealItemNote || '',
                surcharges: mealItemSurcharges,
              }],
              category,
              paymentMethod,
              deliveryGroupId,
              deliveryGroupSortOrder,
              source: 'admin',
            });
          });
          if (lalamoveMultiStopDeliveryId && deliveryStaffId === lalamoveMultiStopDeliveryId) {
            allDeliveryDatetimes.forEach((dateTime) => {
              const { date, mealSlot } = getDateAndMealSlot(dateTime);
              const key = `${index}-${date}-${mealSlot}`;
              if (!groupedMultiStopOrders[key]) {
                groupedMultiStopOrders[key] = [];
              }
              groupedMultiStopOrders[key].push({
                date,
                mealSlot,
                elderId,
                deliveryBy: dateTime,
                formIndex: index,
              });
            });
          }
        });
      });

      setToCreateOrders(toCreateOrders);

      global.logger.debug('toCreateOrders', toCreateOrders);

      const s = new Semaphore(10);
      await Promise.all(toCreateOrders.map((order) =>
        s.runExclusive(async () => {
          try {
            const res = await request(adminCreateOrder, { input: { orders: [order] } });
            const { data: { adminCreateOrder: { data } } } = res;

            results = [...results, ...data];
            setCreateResults(results);
          } catch (e) {
            global.logger.debug(e);
            let message = '';
            if (e.errors && e.errors[0]) {
              switch (e.errors[0].message) {
                case 'The string supplied did not seem to be a phone number':
                  message = `熊貓外送: ${t('送餐對象')}電話不完整或有誤`;
                  break;
                case 'Unable to process order\norder is outside deliverable range':
                  message = `熊貓外送: ${t('送餐對象')}地址不在服務範圍內`;
                  break;
                default:
                  message = e.errors[0].message;
              }
            }
            errors.push({
              elderId: order.elderId,
              message,
            });
          }
        }),
      ));

      setMessage(null);

      const findOrderInGroup = (elderId, deliveryBy) => {
        // eslint-disable-next-line guard-for-in
        for (const key in groupedMultiStopOrders) {
          const orders = groupedMultiStopOrders[key];
          const foundOrder = orders.find((order) => order.elderId === elderId && order.deliveryBy === deliveryBy);
          if (foundOrder) {
            return foundOrder;
          }
        }
        return undefined;
      };
      // assign order id
      results.forEach(({ id, elderId, deliveryBy }) => {
        const orderInGroup = findOrderInGroup(elderId, deliveryBy);
        if (orderInGroup) {
          orderInGroup.id = id;
        }
      });
      setMultiStopOrders(groupedMultiStopOrders);

      if (Object.keys(groupedMultiStopOrders).length !== 0) {
        const s2 = new Semaphore(10);
        await Promise.all(Object.keys(groupedMultiStopOrders).map((key) =>
          s2.runExclusive(async () => {
            try {
              if (groupedMultiStopOrders[key].some(({ id }) => !id)) {
                // 如果有order create失敗就不處理多點配送，避免事後要重新調整
                multiStopOrderResults = [...multiStopOrderResults, {
                  date: groupedMultiStopOrders[key][0].date,
                  mealSlot: groupedMultiStopOrders[key][0].mealSlot,
                  formIndex: groupedMultiStopOrders[key][0].formIndex,
                  status: 'failed',
                  error: '沒有成功建立多點配送中的全部訂單',
                }];
                return;
              }
              const orderIds = groupedMultiStopOrders[key].map(({ id }) => id);
              const { data: { placeMultiStopOrder: { message, errors } } } = await request(placeMultiStopOrder, { input: { orderIds } });

              if (message === 'success') {
                multiStopOrderResults = [...multiStopOrderResults, {
                  date: groupedMultiStopOrders[key][0].date,
                  mealSlot: groupedMultiStopOrders[key][0].mealSlot,
                  formIndex: groupedMultiStopOrders[key][0].formIndex,
                  status: 'success',
                }];
              } else {
                multiStopOrderResults = [...multiStopOrderResults, {
                  date: groupedMultiStopOrders[key][0].date,
                  mealSlot: groupedMultiStopOrders[key][0].mealSlot,
                  formIndex: groupedMultiStopOrders[key][0].formIndex,
                  status: 'failed',
                  error: errors[0],
                }];
              }
            } catch (e) {
              console.log(e);
              multiStopOrderResults = [...multiStopOrderResults, {
                date: groupedMultiStopOrders[key][0].date,
                mealSlot: groupedMultiStopOrders[key][0].mealSlot,
                formIndex: groupedMultiStopOrders[key][0].formIndex,
                status: 'failed',
                error: e.errors && e.errors[0] && e.errors[0].message,
              }];
            }
            setCreateMultiStopOrderResults(multiStopOrderResults);
          }),
        ));
      }
    } catch (e) {
      global.logger.debug(e);
      setMessage(`失敗 ${e.message}`);
      toastr.error(e.message);
    } finally {
      setIsLoading(false);
      setCreateErrors(errors);
    }
  };

  const handleComplete = () => {
    onComplete();
  };

  const triggerDownload = async () => {
    const rows = [];
    toCreateOrders.forEach((order) => {
      const elder = (elderOptions.find(({ id }) => id === order.elderId) || {});

      order.deliveryDatetimes.map((datetime) => {
        const matched = createResults.find(({ elderId, deliveryBy }) => elderId === order.elderId && deliveryBy === datetime);
        const newRow = {};
        newRow[t('送餐對象')] = elder.name;
        newRow[`${t('送餐對象')}編號`] = elder.id;
        newRow[`訂單${t('送餐')}日期`] = formatDatetime(datetime, { weekday: false });
        rows.push({
          ...newRow,
          '訂單編號': matched ? matched.id : '',
          '錯誤訊息': (createErrors.find(({ elderId }) => elderId === order.elderId) || {}).message || '',
          '除錯資訊': JSON.stringify(order),
        });
      });
    });

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, `訂單`);
    writeFile(workbook, `訂單創立清單結果.xlsx`);
  };

  useEffect(() => {
    if (!targetClientId) return;

    (async () => {
      setIsLoading(true);

      setAllFormData([]);

      const clientId = targetClientId !== 'N/A' ? targetClientId : null;
      const [
        elderOptions,
        deliveryGroupOptions,
        elderDeliverGroups,
        orderConfigurations,
      ] = await Promise.all([
        getElderIdSchema(null, null, clientId, true),
        asyncListAll(getDeliveryGroupsByClientByIsActive, {
          clientId: targetClientId,
        }),
        asyncListAll(getElderDeliveryGroupsByClientByStatus, {
          clientId: targetClientId,
          status: { eq: '使用中' },
          limit: 1000,
        }),
        asyncListAll(getConfigurationByCategoryByStatus, {
          category: '訂單',
          status: { eq: '使用中' },
        }),
      ]);


      setElderOptions(elderOptions.sort((sortBy('name'))));
      setDeliveryGroupOptions(deliveryGroupOptions.sort((sortBy('name'))));
      setElderDeliveryGroups(elderDeliverGroups);
      setOrderConfigurations(orderConfigurations);

      setIsLoading(false);

      // auto generate orders
      if (targetClientId !== 'N/A') {
        setAllFormData([{ targetClientId }]);
      }
    })();
  }, [targetClientId]);

  useEffect(() => {
    if (!targetClientId || !clientOptions) return;

    (async () => {
      setIsLoading(true);
      const clientId = targetClientId !== 'N/A' ? targetClientId: null;
      const restaurantOptions = await getRestaurantIdSchema(null, null, clientId, true, onlyShowFacilityRestaurant);

      if (targetClientId !== 'N/A' && onlyShowFacilityCountyRestaurant) {
        const { county: targetCounty } = clientOptions.find(({ id }) => id === targetClientId);
        setRestaurantOptions(restaurantOptions.filter(({ county }) => county === targetCounty));
      } else {
        setRestaurantOptions(restaurantOptions);
      }

      setIsLoading(false);
    })();
  }, [clientOptions, targetClientId, onlyShowFacilityRestaurant, onlyShowFacilityCountyRestaurant]);

  useEffect(() => {
    if (!targetClientId) return;
    (async () => {
      setIsLoading(true);
      const clientId = targetClientId !== 'N/A' ? targetClientId: null;
      const deliveryStaffOptions = await getDeliveryStaffIdSchema(null, null, clientId, true, true, false, false, onlyShowFacilityDeliveryStaff);
      setDeliveryStaffOptions(deliveryStaffOptions);

      const regex = /^\[\d{1,4}\] lalamove\(multi-stop\)$/;
      const deliveryStaffIndex = deliveryStaffOptions.enumNames.findIndex((name) => regex.test(name));
      if (deliveryStaffIndex !== -1) {
        setLalamoveMultiStopDeliveryId(deliveryStaffOptions.enum[deliveryStaffIndex]);
      } else {
        setLalamoveMultiStopDeliveryId();
      }

      setIsLoading(false);
    })();
  }, [targetClientId, onlyShowFacilityDeliveryStaff]);

  useEffect(() => {
    const clientId = cache.get('app:facilityId');
    if (clientId) {
      setTargetClientId(clientId);
      setClientOptions([{
        id: clientId,
        name: cache.get('app:name'),
        county: cache.get('app:location'),
      }]);
      return;
    }

    (async () => {
      setIsLoading(true);
      const [
        clientOptions,
      ] = await Promise.all([
        getClientIdSchema(null, '所屬機構', true),
      ]);
      setClientOptions(clientOptions);

      if (process.env.NODE_ENV === 'development' && clientOptions[1]) {
        setTargetClientId(clientOptions[1].id);
      }

      setIsLoading(false);
    })();
  }, []);

  if (!clientOptions) return null;

  global.logger.debug('re-render all form', allFormData);

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} spacing={2} style={{ backgroundColor: Colors.highlight, marginTop: 8 }}>
        <Grid item xs={12} style={{ marginBottom: 0 }}>
          <FormControl fullWidth>
            <AutoSelect
              id={`clientId`}
              options={['無特定機構', ...clientOptions.map(({ name }) => name)]}
              values={['N/A', ...clientOptions.map(({ id }) => id)]}
              required={false}
              value={targetClientId}
              label="機構"
              helperText={`選定機構後會自動產生${t('送餐對象')}`}
              onChange={(newItem) => {
                setTargetClientId(newItem);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowFacilityCountyRestaurant}
                value={onlyShowFacilityCountyRestaurant}
                onChange={(e) => {
                  setOnlyShowFacilityCountyRestaurant(e.target.checked);
                }}
                name={'onlyShowOrgCountyRestaurant'}
                color="primary"
                disabled={targetClientId === 'N/A'}
              />
            }
            label={`只顯示該機構所在縣市的${t('餐廳')}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowFacilityRestaurant}
                value={onlyShowFacilityRestaurant}
                onChange={(e) => {
                  setOnlyShowFacilityRestaurant(e.target.checked);
                }}
                name={'onlyShowOrgRestaurant'}
                color="primary"
                disabled={targetClientId === 'N/A'}
              />
            }
            label={`只顯示與該機構相關的${t('餐廳')}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyShowFacilityDeliveryStaff}
                value={onlyShowFacilityDeliveryStaff}
                onChange={(e) => {
                  setOnlyShowFacilityDeliveryStaff(e.target.checked);
                }}
                name={'onlyShowFacilityDeliveryStaff'}
                color="primary"
                disabled={targetClientId === 'N/A'}
              />
            }
            label={`只顯示該機構的${t('送餐大使')}`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showMap}
                value={showMap}
                onChange={(e) => {
                  setShowMap(e.target.checked);
                }}
                name={'showMap'}
                color="primary"
              />
            }
            label="顯示地圖"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={unlockDates}
                value={unlockDates}
                onChange={(e) => {
                  setUnlockDates(e.target.checked);
                }}
                name={'unlockDates'}
                color="primary"
              />
            }
            label={`解鎖過去的${t('送餐')}日期`}
          />
        </Grid>
      </Grid>

      {targetClientId && restaurantOptions && deliveryStaffOptions && elderOptions && allFormData &&
      <Fragment>
        <div style={{ display: 'flex', marginTop: 20, flexDirection: 'row' }}>
          <EmojiObjectsOutlinedIcon style={{ color: 'gold' }} fontSize='medium' />
          <Typography variant='body1'>下次週期性排單將新增{moment().add(31, 'days').format('LL')}的訂單</ Typography>
        </div>
        {allFormData.map((formDataItem, index)=>(
          <OrderBatchForm
            key={index}
            index={index}
            targetClientId={targetClientId}
            onRemove={()=>{
              allFormData.splice(index, 1);
              setAllFormData([...allFormData]);
            }}
            onUpdate={(updated) => {
              // global.logger.debug('on formdata update', updated);
              Object.assign(formDataItem, updated);
            }}
            showMap={showMap}
            unlockDates={unlockDates}
            deliveryGroupOptions={deliveryGroupOptions}
            elderDeliveryGroups={elderDeliveryGroups}
            elderOptions={elderOptions}
            restaurantOptions={restaurantOptions}
            deliveryStaffOptions={deliveryStaffOptions}
            orderConfigurations={orderConfigurations}
          />
        ))}
        <Grid item container xs={12}>
          <Button
            variant="outlined"
            onClick={() => setAllFormData([...allFormData, { targetClientId }])}
          >
            新增批量訂單
          </Button>
          {process.env.NODE_ENV === 'development' &&
            <Button
              style={{ marginLeft: 16 }}
              variant="outlined"
              onClick={() => {
                global.logger.debug(JSON.stringify(allFormData, null, 2));
              }}
            >
              檢查
            </Button>}
          <div style={{ flex: 1, textAlign: 'center', color: Colors.raised, lineHeight: '36px' }}>
            {message}
          </div>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            送出
          </Button>
        </Grid>
      </Fragment>}
      {openConfirmDialog &&
        <FormDialog
          title={'訂單列表'}
          openOnInit={true}
          fullScreen={true}
          hideCloseButton={true}
        >
          <Grid container spacing={2}>
            {Object.keys(lalamoveMultiStopOrdersQuoteResult).length !== 0 &&
              <Grid item container spacing={1}>
                <Grid item xs={12} container direction='row' alignItems='center'>
                  <MotorcycleIcon style={{ marginRight: 4 }}/>
                  <Typography variant="h5" component="p">
                    檢查多點配送服務範圍
                  </Typography>
                </Grid>
                <Grid item xs={12} container style={{ marginLeft: 20 }}>
                  {lalamoveMultiStopOrdersQuoteResult.map((multiStopOrder) => {
                    const { result, formIndex } = multiStopOrder;
                    const formIndexDisplay = formIndex + 1;
                    return (
                      <Grid item xs={12} key={`quote-result-${formIndex}`} container direction='row'>
                        {!result ? <CircularProgress color="primary" size={24} /> :
                          result && result.code === 'SUCCESS' ?
                            <CheckIcon color="primary" /> : <CancelIcon color="secondary" /> }
                        <Typography style={{ marginTop: 2, marginLeft: 4 }}>
                          {`批量訂單${formIndexDisplay}`}
                        </Typography>
                        {result && result.code !== 'SUCCESS' &&
                        <Grid item xs={12}>
                          <Typography variant="h5" component="p" color="secondary">
                            {result.message}
                          </Typography>
                        </Grid>}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            }
            <Grid item container spacing={1}>
              {toCreateOrders.length !== 0 &&
                <Grid item xs={12} container direction='row' alignItems='center'>
                  <ListAltIcon style={{ marginRight: 4 }}/>
                  <Typography variant="h5">
                    建立訂單
                  </Typography>
                </Grid>}
              {
                toCreateOrders.map((order, index) => (
                  <Grid item container xs={12} key={index} spacing={1} style={{ marginLeft: 20 }}>
                    <Grid item xs={12}>
                      <Typography variant="h6" component="p">
                        {(elderOptions.find(({ id })=> id === order.elderId) || {}).name || ''}
                      </Typography>
                    </Grid>
                    {order.deliveryDatetimes.map((datetime) => {
                      const matched = createResults.find(({ elderId, deliveryBy }) => elderId === order.elderId && deliveryBy === datetime);
                      return (
                        <Grid item sm={4} md={3} lg={2} container align="center" key={datetime}>
                          <Grid item xs={12} container align="center">
                            {isLoading && !matched ? <CircularProgress color="primary" size={24} /> :
                              matched ?
                                <CheckIcon color="primary" /> : <CancelIcon color="secondary" />}
                            <Typography style={{ marginTop: 2, marginLeft: 4 }}>
                              {formatDatetime(datetime, { weekday: false })}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid item xs={12}>
                      <Typography variant="h5" component="p" color="secondary">
                        {(createErrors.find(({ elderId })=> elderId === order.elderId) || {}).message || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              }
            </Grid>
            {Object.keys(multiStopOrders).length !== 0 &&
              <Grid item container spacing={1}>
                <Grid item xs={12} container direction='row' alignItems='center'>
                  <AccountTreeIcon style={{ marginRight: 4 }}/>
                  <Typography variant="h5" component="p">
                    多點配送
                  </Typography>
                </Grid>
                <Grid item xs={12} container style={{ marginLeft: 20 }}>
                  {Object.keys(multiStopOrders).map((key) => {
                    const { date, mealSlot, formIndex } = multiStopOrders[key][0];
                    const dateDisplay = formatDatetime(date, { weekday: false, hour: false, minute: false });
                    const mealSlotDisplay = mealSlot === 'lunch' ? '午餐' : '晚餐';
                    const formIndexDisplay = formIndex + 1;
                    const matched = createMultiStopOrderResults.find(
                      (result) => result.date === date && result.mealSlot === mealSlot && result.formIndex === formIndex,
                    );
                    return (
                      <Grid item xs={12} key={key} container direction='row'>
                        {isLoading ? <CircularProgress color="primary" size={24} /> :
                          matched && matched.status === 'success' ?
                            <CheckIcon color="primary" /> : <CancelIcon color="secondary" /> }
                        <Typography style={{ marginTop: 2, marginLeft: 4 }}>
                          {`批量訂單${formIndexDisplay} ${dateDisplay} ${mealSlotDisplay}`}
                        </Typography>
                        {!isLoading && matched && matched.status !== 'success' && matched.error &&
                        <Grid item xs={12}>
                          <Typography variant="h5" component="p" color="secondary">
                            {matched.error}
                          </Typography>
                        </Grid>}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            }
            <Grid item container xs={12} justifyContent="center">
              <Button
                variant="outlined"
                onClick={triggerDownload}
                disabled={isLoading}
                style={{ marginRight: 16 }}
              >
                下載結果
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={handleComplete}
              >
                關閉視窗
                {isLoading && <CircularProgress color="primary" size={16} style={{ marginLeft: 8 }} />}
              </Button>
            </Grid>
          </Grid>
        </FormDialog>
      }
    </Grid>
  );
}

AdminCreateBulkOrderForm.propTypes = {
  onComplete: PropTypes.func,
  formData: PropTypes.object,
};
