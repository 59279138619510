export const getRestaurantMealsByRestaurantByIsActive = /* GraphQL */ `
  query GetRestaurantMealsByRestaurantByIsActive(
    $restaurantId: ID
    $isActive: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantMealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRestaurantMealsByRestaurantByIsActive(
      restaurantId: $restaurantId
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        cost
        surcharges {
          name
          price
        }
        previewS3Keys
        imageS3Keys
        tags {
          items {
            id
            restaurantMealId
            tagId
            tag {
              id
              category
              subcategory
              color
              label
            }
          }
          nextToken
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
