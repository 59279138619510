const uiSchema = {
  'surcharges': {
    'items': {
      'name': { 'ui:width': '50%' },
      'price': { 'ui:width': '50%' },
      'ui:title': '',
    },
    'ui:options': {
      'orderable': false,
    },
  },
};

export default uiSchema;
